import { StudentState } from "@/store/room/interface";
import { fabric } from "fabric";
import { getFourPoints } from "@/utils/fabric-utils";

export const renderPolylineAsLineOnCanvas = (pencilPath: Array<any>, canvas: any, studentOneAndOneId: string, student: StudentState | undefined) => {
  if (!studentOneAndOneId || studentOneAndOneId === student?.id) {
    canvas.remove(...canvas.getObjects().filter((obj: any) => obj.type === "polyline"));
    pencilPath.forEach((data: any) => {
      const points = data.points?.length === 1 ? getFourPoints(data.points[0]) : data.points;
      new fabric.Polyline.fromObject({ points }, (item: any) => {
        item.id = "pencil";
        item.fill = "transparent";
        item.stroke = data.strokeColor;
        item.strokeWidth = data.strokeWidth;
        item.strokeLineCap = "round";
        item.strokeLineJoin = "round";
        item.selectable = false;
        item.isDone = data.isDone;
        item.ratio = data.ratio;
        item.lineId = data.id;
        item.lineIdRelated = data.lineIdRelated;
        item.objectId = data.objectId;
        canvas.add(item);
      });
    });
  }
};
