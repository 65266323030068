import { computed, onMounted } from "vue";
import { AuthService, fmtMsg } from "vue-glcommonui";
import { ForceLogoutLocale } from "@/locales/localeid";
import { ForceLogoutCodes } from "@/utils/utils";

export function useReloadDetector() {
  const MAX_RELOAD_COUNT = 10;
  const TIME_WINDOW = 10 * 1000; // 10 seconds
  const reloadDataKey = "reloadDataKey";

  const MsgConfirm = computed(() => fmtMsg(ForceLogoutLocale.Confirm, { code: ForceLogoutCodes.ReloadForever }));

  const isInClassPage = () => {
    const currentUrl = window.location.pathname;

    const uuidPattern = "[a-f0-9-]{36}";

    const regexTeacherClassPath = new RegExp(`^\\/class\\/${uuidPattern}$`);
    const regexStudentClassPath = new RegExp(`^\\/student\\/${uuidPattern}\\/class\\/${uuidPattern}$`);
    return regexTeacherClassPath.test(currentUrl) || regexStudentClassPath.test(currentUrl);
  };

  const resetReloadData = () => {
    const initialData = { count: 0, lastReloadTime: Date.now() };
    localStorage.setItem(reloadDataKey, JSON.stringify(initialData));
  };

  const clearSessionAndRedirect = () => {
    resetReloadData();
    AuthService.storePagethenSignoutRedirect();
  };

  const detectReloads = () => {
    const isSigningOut = window.location.href.indexOf("oidc/signout") !== -1;
    if (isInClassPage() || isSigningOut) {
      resetReloadData();
      return;
    }

    const storedData = localStorage.getItem(reloadDataKey);
    const reloadData = storedData ? JSON.parse(storedData) : { count: 0, lastReloadTime: Date.now() };

    const currentTime = Date.now();

    if (currentTime - reloadData.lastReloadTime < TIME_WINDOW) {
      reloadData.count += 1;
    } else {
      reloadData.count = 1;
    }
    reloadData.lastReloadTime = currentTime;

    localStorage.setItem(reloadDataKey, JSON.stringify(reloadData));

    if (reloadData.count >= MAX_RELOAD_COUNT) {
      alert(MsgConfirm.value);
      clearSessionAndRedirect();
    }
  };

  onMounted(() => {
    detectReloads();
  });

  return {};
}
