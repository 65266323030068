export enum TeacherWSEvent {
  JOIN_CLASS = "1203",
  END_CLASS = "1211",
  SET_TEACHING_MODE = "1212",
  UPDATE_STUDENT_BADGE = "1202",
  START_LESSON_PLAN = "1115",
  END_LESSON_PLAN = "1116",
  SET_ITEM_CONTENT_LESSON_PLAN = "1130",
  CLEAR_RAISING_HAND = "1112",
  UPDATE_LESSON_ACTION = "1113",
  EVENT_UPDATE_POINTER = "1108",
  EVENT_ANNOTATION_UPDATE_MODE = "1105",
  EVENT_TEACHER_ANNOTATION_ADD_BRUSHSTROKE = "1107",
  EVENT_TEACHER_ANNOTATION_SET_BRUSHSTROKE = "1103",
  EVENT_TEACHER_ANNOTATION_CLEAR_BRUSHSTROKE = "1106",
  EVENT_TEACHER_SET_ONE_TO_ONE = "1213",
  EVENT_TEACHER_FAIL_SET_ONE_TO_ONE = "1226",
  EVENT_TEACHER_SET_WHITEBOARD = "1214",
  EVENT_TEACHER_DRAW_LASER_PEN = "1118",
  EVENT_TEACHER_UPDATE_STUDENT_PALETTE = "1120",
  TEACHER_CREATE_FABRIC_OBJECT = "1217",
  TEACHER_MODIFY_FABRIC_OBJECT = "1218",
  TEACHER_ZOOM_SLIDE = "1219",
  TEACHER_MOVE_ZOOMED_SLIDE = "1220",
  EVENT_TEACHER_DRAW_PENCIL_PEN = "1126",
  EVENT_UPDATE_SHAPE = "1122",
  TEACHER_UPDATE_SESSION_LESSON_AND_UNIT = "1222",
  EVENT_TEACHER_SET_MEDIA_STATE = "1223",
  EVENT_TEACHER_SCROLL_PDF = "1303",

  // RELATING TO HELPER
  HELPER_REQUEST_JOIN_CLASS = "1500",
  CANCEL_REQUEST_TO_JOIN_SESSION = "1509",
  TEACHER_SHOW_HELPER_VIDEO = "TH001",
  TEACHER_HIDE_HELPER_VIDEO = "TH002",
  TEACHER_REMOVE_HELPER = "TH003",
  EVENT_TEACHER_TOGGLE_INDEPENDENT_MODE = "1302",
  EVENT_STUDENT_SELECT_ITEM_IN_INDEPENDENT = "2114",
  EVENT_HELPER_BECOME_TEACHER = "1508",
  EVENT_HELPER_TOGGLE_TEACHER_AUDIO = "1510",
  EVENT_TOGGLE_TEAM_MODE = "TM001",
  EVENT_EDIT_TEAM = "TM002",
  EVENT_RESET_TEAM = "TM003",
  EVENT_TEACHER_UPDATE_CANVAS_OBJECT = "TM004",
  EVENT_TEACHER_TOGGLE_STUDENT_MEDIA_DEVICES = "TM005",
}
