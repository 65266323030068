import { MutationTree } from "vuex";
import { AppState, AppView, LayoutType, ToastData, UserRole } from "./state";

const mutations: MutationTree<AppState> = {
  setToast(state, payload: ToastData) {
    state.toast = payload;
  },
  setLayout(state, payload: { layout: LayoutType }) {
    state.layout = payload.layout;
  },
  setAppView(state, payload: { appView: AppView }) {
    state.appView = payload.appView;
  },
  setCameraDeviceId(state, payload: string) {
    state.cameraDeviceId = payload ?? "";
  },
  setMicrophoneDeviceId(state, payload: string) {
    state.microphoneDeviceId = payload ?? "";
  },
  setSpeakerDeviceId(state, payload: string) {
    state.speakerDeviceId = payload ?? "";
  },
  setUserRoleByView(state, payload: UserRole) {
    state.userRole = payload;
  },
  setLowBandWidth(state, payload: boolean) {
    state.isLowBandWidth = payload;
  },
  setReplacedClassId(state, payload: string) {
    state.replacedClassId = payload;
  },
  setHavePermissionCamera(state, payload: boolean) {
    state.havePermissionCamera = payload;
  },
  setHavePermissionMicrophone(state, payload: boolean) {
    state.havePermissionMicrophone = payload;
  },
};

export default mutations;
