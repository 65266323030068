import { FabricObject } from "@/ws";
import { GetterTree } from "vuex";
import { AnnotationState, LastFabricUpdated, Pointer, UserShape } from "./state";

const DEFAULT_RESOLUTION = "1024X722";

const getters: GetterTree<AnnotationState, any> = {
  pointer(state: AnnotationState): Pointer {
    return state.pointer;
  },
  mode(state: AnnotationState): number {
    return state.mode;
  },
  isPointerMode(state: AnnotationState): boolean {
    return state.mode === 1;
  },
  isDrawMode(state: AnnotationState): boolean {
    return state.mode === 2;
  },
  isStickerMode(state: AnnotationState): boolean {
    return state.mode === 3;
  },
  //** Paths */
  shapes(state: AnnotationState): Array<string> {
    return state.drawing?.brushstrokes;
  },
  //** Paths 1:1*/
  oneOneTeacherStrokes(state: AnnotationState): Array<string> {
    return state.oneToOne?.brushstrokes;
  },
  //** circle, square ... */
  teacherShape(state: AnnotationState): Array<UserShape> {
    return state.drawing?.teacherShapes;
  },
  //** circle, square 1:1 ... */
  oneTeacherShape(state: AnnotationState): Array<UserShape> {
    return state.oneToOne?.teacherShapes;
  },
  //** Textbox */
  fabricItems(state: AnnotationState): FabricObject[] {
    return state.drawing.fabrics;
  },
  //** Textbox 1:1 */
  fabricItemsOneToOne(state: AnnotationState): FabricObject[] {
    return state.oneToOne.fabrics;
  },
  //** Path created from realtime points */
  pencilPath(state: AnnotationState) {
    return state.pencilPath;
  },
  //** Path created from realtime points */
  laserPath(state: AnnotationState): any {
    return state.laserPath;
  },
  //* Currently it is only used to save "text boxes", in the future it will be used to save all annotations */
  drawings(state: AnnotationState): FabricObject[] {
    return state.drawings;
  },
  lastFabricUpdated(state: AnnotationState): LastFabricUpdated | null {
    return state.lastFabricUpdated;
  },
  lastFabricIdDeleted(state: AnnotationState): string | null {
    return state.lastFabricIdDeleted;
  },
  undoShape(state: AnnotationState): Array<string> {
    return state.drawing?.brushstrokes;
  },
  undoStrokeOneOne(state: AnnotationState): Array<string> {
    return state.oneToOne?.brushstrokes;
  },
  imgWidth(state: AnnotationState): number {
    return state.imgWidth || parseInt(DEFAULT_RESOLUTION.split("X")[0]);
  },
  imgHeight(state: AnnotationState): number {
    return state.imgHeight || parseInt(DEFAULT_RESOLUTION.split("X")[1]);
  },
  isImgProcessing(state: AnnotationState): boolean {
    return state.isImgProcessing ?? false;
  },
};

export default getters;
