import { ActionTree } from "vuex";
import { StudyMode } from "../interfaces";
import { ClassTeachingState } from "./state";

const actions: ActionTree<ClassTeachingState, any> = {
  setMode(store, payload: StudyMode) {
    store.commit("setMode", payload);
  },
};

export default actions;
