import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot } from "vue"
import _imports_0 from '@/assets/images/audio-wave.gif'
import _imports_1 from '@/assets/audio/ConnectTestSound.mp3'


const _hoisted_1 = { class: "text-base font-medium" }
const _hoisted_2 = { class: "mt-6 flex flex-col items-stretch space-y-4" }
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = { class: "flex flex-1 justify-between items-center" }
const _hoisted_5 = { class: "flex flex-1 space-x-1.5 items-center" }
const _hoisted_6 = { class: "relative" }
const _hoisted_7 = {
  key: 0,
  src: _imports_0,
  class: "h-4 w-4 absolute bottom-2 right-2 translate-x-full translate-y-full",
  alt: ""
}
const _hoisted_8 = ["onplay", "onpause"]
const _hoisted_9 = {
  key: 0,
  class: "text-red-600 pb-2"
}
const _hoisted_10 = { class: "flex items-center space-x-4" }
const _hoisted_11 = { class: "flex flex-1 justify-between items-center" }
const _hoisted_12 = { class: "flex flex-1 space-x-1.5 items-center" }
const _hoisted_13 = {
  key: 1,
  class: "flex justify-end"
}
const _hoisted_14 = { class: "w-[600px]" }
const _hoisted_15 = {
  key: 2,
  class: "text-red-600 pb-2"
}
const _hoisted_16 = {
  key: 3,
  class: "flex items-center space-x-4"
}
const _hoisted_17 = { class: "flex flex-1 justify-between items-center" }
const _hoisted_18 = { class: "flex flex-1 space-x-1.5 items-center" }
const _hoisted_19 = {
  key: 4,
  class: "text-red-600 pb-2"
}
const _hoisted_20 = {
  key: 5,
  class: "flex justify-end"
}
const _hoisted_21 = { class: "w-[600px]" }
const _hoisted_22 = { class: "w-full aspect-w-16 aspect-h-9" }
const _hoisted_23 = ["id"]
const _hoisted_24 = { class: "rounded-lg overflow-hidden w-full h-full object-cover" }
const _hoisted_25 = {
  key: 0,
  class: "relative w-full h-full flex flex-col justify-center items-center bg-slate-900"
}
const _hoisted_26 = { class: "device-tester__camera--player__notify" }
const _hoisted_27 = { class: "device-tester__camera--player__icon" }
const _hoisted_28 = { class: "device-tester__camera--player__text text-center px-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SpeakerWaveIcon = _resolveComponent("SpeakerWaveIcon")!
  const _component_DeviceErrorTooltip = _resolveComponent("DeviceErrorTooltip")!
  const _component_SelectOption = _resolveComponent("SelectOption")!
  const _component_Select = _resolveComponent("Select")!
  const _component_MicrophoneIcon = _resolveComponent("MicrophoneIcon")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_Progress = _resolveComponent("Progress")!
  const _component_VideoCameraIcon = _resolveComponent("VideoCameraIcon")!
  const _component_VideoCameraSlashIcon = _resolveComponent("VideoCameraSlashIcon")!
  const _component_VideoPlaceholder = _resolveComponent("VideoPlaceholder")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.RemoteSetUpText), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_SpeakerWaveIcon, { class: "w-6 h-6" }),
            _createElementVNode("div", null, _toDisplayString(_ctx.CheckSpeaker), 1),
            (!_ctx.havePermissionSpeaker)
              ? (_openBlock(), _createBlock(_component_DeviceErrorTooltip, {
                  key: 0,
                  message: _ctx.MsgSpeakerErrorInfo,
                  altText: 'Speaker error info'
                }, null, 8, ["message"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isPlayingSound ? _ctx.PauseCircleIcon : _ctx.PlayCircleIcon), {
              class: "w-10 h-10 text-gs-purple cursor-pointer",
              onClick: _ctx.toggleSpeaker
            }, null, 8, ["onClick"])),
            (_ctx.isPlayingSound)
              ? (_openBlock(), _createElementBlock("img", _hoisted_7))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("audio", {
            loop: "",
            id: "audio",
            class: "hidden",
            onplay: _ctx.onSoundPlay,
            onpause: _ctx.onSoundPause
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("source", {
              src: _imports_1,
              type: "audio/mp3"
            }, null, -1)
          ]), 8, _hoisted_8)
        ]),
        _createVNode(_component_Select, {
          placeholder: _ctx.SystemDefaultSpeakerDevice,
          style: {"width":"600px"},
          disabled: !_ctx.isCheckSpeaker || !_ctx.listSpeakersId.length,
          value: _ctx.currentSpeakerLabel,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentSpeakerLabel) = $event)),
          ref: "select",
          onChange: _ctx.handleSpeakerChange
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listSpeakersId, (deviceId) => {
              return (_openBlock(), _createBlock(_component_SelectOption, {
                key: deviceId,
                value: deviceId
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.listSpeakers.find((speaker) => speaker.deviceId === deviceId)?.label), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["placeholder", "disabled", "value", "onChange"])
      ]),
      (!_ctx.havePermissionSpeaker)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.warningMsgSpeaker), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_MicrophoneIcon, { class: "w-6 h-6" }),
            _createElementVNode("div", null, _toDisplayString(_ctx.CheckMic), 1),
            (!_ctx.havePermissionMicrophone || _ctx.agoraMicError)
              ? (_openBlock(), _createBlock(_component_DeviceErrorTooltip, {
                  key: 0,
                  message: _ctx.MsgMicroErrorInfo,
                  altText: 'Micro error info'
                }, null, 8, ["message"]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_Switch, {
            disabled: _ctx.isSettingUpAudioTrack,
            checked: _ctx.isOpenMic,
            "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpenMic) = $event))
          }, null, 8, ["disabled", "checked"])
        ]),
        _createVNode(_component_Select, {
          placeholder: _ctx.SelectDevice,
          class: "",
          style: {"width":"600px"},
          disabled: !_ctx.isOpenMic,
          value: _ctx.currentMicLabel,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentMicLabel) = $event)),
          ref: "select",
          onChange: _ctx.handleMicroChange
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listMicsId, (deviceId) => {
              return (_openBlock(), _createBlock(_component_SelectOption, {
                key: deviceId,
                value: deviceId
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.listMics.find((mic) => mic.deviceId === deviceId)?.label), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["placeholder", "disabled", "value", "onChange"])
      ]),
      (_ctx.listMics.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_Progress, {
                strokeWidth: 24,
                percent: !_ctx.isOpenMic ? 0 : _ctx.volumeByPercent,
                strokeColor: "#2f0b57",
                "show-info": false,
                class: "progress"
              }, null, 8, ["percent"])
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.havePermissionMicrophone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.warningMsgMicrophone), 1))
        : _createCommentVNode("", true),
      (_ctx.showCameraOptions)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_VideoCameraIcon, { class: "w-6 h-6" }),
                _createElementVNode("div", null, _toDisplayString(_ctx.CheckCam), 1),
                (!_ctx.havePermissionCamera || _ctx.agoraCamError)
                  ? (_openBlock(), _createBlock(_component_DeviceErrorTooltip, {
                      key: 0,
                      message: _ctx.MsgCameraErrorInfo,
                      altText: 'Camera error info'
                    }, null, 8, ["message"]))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_Switch, {
                disabled: _ctx.isSettingUpVideoTrack,
                checked: _ctx.isOpenCam,
                "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isOpenCam) = $event))
              }, null, 8, ["disabled", "checked"])
            ]),
            _createVNode(_component_Select, {
              placeholder: _ctx.SelectDevice,
              style: {"width":"600px"},
              disabled: !_ctx.isOpenCam,
              value: _ctx.currentCamId,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentCamId) = $event)),
              ref: "select",
              onChange: _ctx.handleCameraChange
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listCamsId, (deviceId) => {
                  return (_openBlock(), _createBlock(_component_SelectOption, {
                    key: deviceId,
                    value: deviceId
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.listCams.find((cam) => cam.deviceId === deviceId)?.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["placeholder", "disabled", "value", "onChange"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showCameraOptions && !_ctx.havePermissionCamera)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.warningMsgCamera), 1))
        : _createCommentVNode("", true),
      (_ctx.showCameraOptions)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _withDirectives(_createElementVNode("div", {
                  ref: 
              (el) => {
                _ctx.playerRef = el;
              }
            ,
                  id: _ctx.videoElementId,
                  class: "rounded-lg overflow-hidden w-full h-full object-cover"
                }, null, 8, _hoisted_23), [
                  [_vShow, _ctx.isOpenCam && _ctx.currentCam && !_ctx.agoraCamError]
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_24, [
                  (_ctx.isCurrentCamError || !_ctx.isOpenCam)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _createElementVNode("div", _hoisted_26, [
                          _createElementVNode("div", _hoisted_27, [
                            _createVNode(_component_VideoCameraSlashIcon, { class: "w-8 h-8 text-white" })
                          ]),
                          _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.isCurrentCamError && _ctx.isOpenCam ? _ctx.MsgCameraErrorInfo : _ctx.CamOff), 1)
                        ])
                      ]))
                    : (_openBlock(), _createBlock(_component_VideoPlaceholder, {
                        key: 1,
                        style: {"z-index":"1"}
                      }))
                ], 512), [
                  [_vShow, !_ctx.isOpenCam || _ctx.isCurrentCamError || !_ctx.currentCam]
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 64))
}