import { computed } from "vue";
import { useStore } from "vuex";
import { TeachingMode } from "@/models";

export const useTeachingMode = () => {
  const { getters } = useStore();
  return {
    isLpSidebarShrink: computed<boolean>(() => getters["teacherRoom/teachingMode"] === TeachingMode.CloseLessonPlan),
  };
};
