import { AgoraClient, AgoraClientOptions, AgoraEventHandler } from "@/agora";
import { GLSocketClient, WSEventHandler } from "@/ws";

export interface RoomOptions {
  agora: AgoraClientOptions;
}

export abstract class BaseRoomManager<T extends GLSocketClient> {
  agoraClient!: AgoraClient;
  options!: RoomOptions;
  WSClient!: T;

  abstract join(options: {
    classId: string;
    studentId?: string;
    teacherId?: string;
    camera?: boolean;
    microphone?: boolean;
    callingEventHandlers: AgoraEventHandler;
  }): Promise<any>;

  registerEventHandler(eventHandler: WSEventHandler) {
    return this.WSClient.registerEventHandler(eventHandler);
  }

  setCamera(options: { enable: boolean; videoEncoderConfigurationPreset?: string }) {
    return this.agoraClient.setCamera(options);
  }

  setMicrophone(options: { enable: boolean }) {
    return this.agoraClient.setMicrophone(options);
  }

  updateCameraDevice() {
    return this.agoraClient.updateCameraDevice();
  }

  updateMicrophoneDevice() {
    return this.agoraClient.updateMicrophoneDevice();
  }

  async updateSpeakerDevice() {
    return this.agoraClient.updateSpeakerDevice();
  }

  async updateAudioAndVideoFeed(cameras: Array<string>, audios: Array<string>) {
    await this.agoraClient.updateAudioAndVideoFeed(cameras, audios);
  }

  async close() {
    return this.agoraClient.reset();
  }
}
