import { StudentRoomManager } from "@/manager/room/student.manager";
import { ClassModel, RoomModel } from "@/models";
import { GLApiStatus, GLError } from "@/models/error.model";
import { UserModel } from "@/models/user.model";
import { isDesktopBrowser } from "@/utils/utils";
import { HelperState, StudentState, TeacherState } from "../interface";

export enum ClassAction {
  DEFAULT = "default",
  LISTEN = "listen",
  QUESTION = "question",
  QUIET = "quiet",
  SING = "sing",
  SPEAK = "speak",
  INTERACTIVE = "interactive",
}

export const ClassActionFromValue = (val: number): ClassAction => {
  if (val === 0) return ClassAction.DEFAULT;
  if (val === 1) return ClassAction.LISTEN;
  if (val === 2) return ClassAction.QUESTION;
  if (val === 3) return ClassAction.QUIET;
  if (val === 4) return ClassAction.SING;
  if (val === 5) return ClassAction.SPEAK;
  if (val === 6) return ClassAction.INTERACTIVE;
  throw new Error("UnSupported Class Action " + val);
};

export const ClassActionToValue = (classAction: ClassAction): number => {
  let val = 0;
  switch (classAction) {
    case ClassAction.DEFAULT:
      val = 0;
      break;
    case ClassAction.LISTEN:
      val = 1;
      break;
    case ClassAction.QUESTION:
      val = 2;
      break;
    case ClassAction.QUIET:
      val = 3;
      break;
    case ClassAction.SING:
      val = 4;
      break;
    case ClassAction.SPEAK:
      val = 5;
      break;
    case ClassAction.INTERACTIVE:
      val = 6;
      break;
  }
  return val;
};

export interface StudentRoomState {
  info?: RoomModel;
  user?: UserModel;
  teacher?: TeacherState;
  student?: StudentState;
  students: Array<StudentState>;
  helper?: HelperState;
  manager?: StudentRoomManager;
  classes: Array<ClassModel>;
  error: GLError | null;
  cameraLock: boolean;
  microphoneLock: boolean;
  classAction: ClassAction;
  idOne: string;
  idHelperOne: string;
  oneToOneWithHelperIgnoreTeacherVoice: boolean;
  speakingUsers: Array<string>;
  showWhiteBoard: boolean;
  isDisconnected: boolean;
  isJoined: boolean;
  teacherIsDisconnected: boolean;
  avatarStudentOneToOne: string;
  avatarStudentHelperOneToOne: string;
  apiStatus: GLApiStatus | null;
  videosFeedVisible: boolean;
  mediaState: boolean;
  initClassLoading: boolean;
  isSessionEnded: boolean;
}

export const initState = (): StudentRoomState => ({
  info: undefined,
  user: undefined,
  teacher: undefined,
  student: undefined,
  students: [],
  manager: undefined,
  classes: [],
  error: null,
  cameraLock: false,
  microphoneLock: false,
  classAction: ClassAction.DEFAULT,
  idOne: "",
  idHelperOne: "",
  //** Should helper and student in 1:1 hear the teacher's voice */
  oneToOneWithHelperIgnoreTeacherVoice: false,
  speakingUsers: [],
  showWhiteBoard: false,
  //** Determine local user is disconnect or not */
  isDisconnected: !navigator.onLine,
  teacherIsDisconnected: false,
  isJoined: false,
  avatarStudentOneToOne: "",
  avatarStudentHelperOneToOne: "",
  apiStatus: null,
  videosFeedVisible: isDesktopBrowser,
  mediaState: false,
  initClassLoading: false,
  isSessionEnded: false,
});

const state: StudentRoomState = initState();

export default state;
