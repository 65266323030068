import { ChangeLessonUnitLocale, DeviceTesterLocale, HelperLocales } from "@/locales/localeid";
import { ClassRoomModel, UnitAndLesson } from "@/models";
import { HelperState, StudentState } from "@/store/room/interface";
import { getListUnitByClassAndGroup } from "@/views/teacher-home/lesson-helper";
import { Button, Checkbox, Divider, Modal, Row, Select, Skeleton, Space, Spin, Switch, notification } from "ant-design-vue";
import { computed, defineComponent, ref, watch } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { useStore } from "vuex";
import { useSendWebSocketMsg } from "@/hooks/use-send-websocket-msg";

export default defineComponent({
  components: {
    Modal,
    Switch,
    Select,
    SelectOption: Select.Option,
    Button,
    Skeleton,
    Divider,
    Row,
    Space,
    Spin,
    Checkbox,
  },
  emits: ["go-to-class", "on-join-session", "on-close-modal"],
  setup() {
    const { getters, dispatch, commit } = useStore();
    const { requestClearBoard } = useSendWebSocketMsg();
    const visible = ref(false);
    const currentUnit = ref();
    const loading = ref(false);
    const currentLesson = ref();
    const listLessonByUnit = ref();
    const preventCloseModal = ref(true);
    const loadingInfo = ref(false);
    const isCompleted = ref(false);
    const messageUpdateLessonAndUnit = ref("");
    const unitInfo = ref<UnitAndLesson[]>([]);
    const classInfo = computed<ClassRoomModel>(() => getters["teacherRoom/info"]?.classInfo);
    const helperInfo = computed<HelperState | undefined>(() => getters["teacherRoom/helperInfo"]);
    const studentModeOneWithAnotherTeacher = computed<StudentState | null>(() => getters["teacherRoom/getStudentModeOneWithAnotherTeacher"]);
    const getListLessonByUnit = async () => {
      if (!classInfo.value) return;
      try {
        loadingInfo.value = true;
        unitInfo.value = await getListUnitByClassAndGroup(classInfo.value?.classId, classInfo.value?.groupId);
      } catch (err) {
        const message = err?.body?.message;
        if (message) {
          notification.error({
            message,
          });
        }
      }
      loadingInfo.value = false;
    };

    const initialSetup = async () => {
      getListLessonByUnit();
    };

    const showModal = () => {
      const helperName = helperInfo.value?.name;
      const studentName = studentModeOneWithAnotherTeacher.value?.englishName;
      if (helperName && studentName) {
        notification.info({
          message: fmtMsg(HelperLocales.PreventChangeUnitAndLesson, { helperName, studentName }),
          placement: "topLeft",
        });
        return;
      }
      visible.value = true;
    };

    const destroy = async () => {
      currentUnit.value = null;
      currentLesson.value = null;
      messageUpdateLessonAndUnit.value = "";
    };

    watch(unitInfo, (currentValue) => {
      if (!currentValue) return;
      const unitDefault = classInfo.value?.unit;
      const lessonDefault = classInfo.value?.lesson;
      const currentUnitIndex = currentValue?.findIndex((item: UnitAndLesson) => item.unit === unitDefault);
      listLessonByUnit.value = unitInfo.value[currentUnitIndex]?.sequence;
      if (currentUnitIndex >= 0) {
        currentUnit.value = currentValue[currentUnitIndex].unit;
        currentLesson.value = lessonDefault;
      } else {
        const initUnit = currentValue?.[0]?.unit;
        const initLesson = currentValue?.[initUnit]?.sequence[0];
        if (initUnit) {
          currentUnit.value = initUnit;
          currentLesson.value = initLesson;
        }
      }
    });

    watch(currentLesson, () => {
      messageUpdateLessonAndUnit.value = "";
    });

    watch(visible, async (currentValue) => {
      if (!currentValue) {
        await destroy();
        return;
      }
      await initialSetup();
    });

    const handleUnitChange = (unit: any) => {
      currentUnit.value = unit;
      const currentUnitIndex = unitInfo.value?.findIndex((item: UnitAndLesson) => item.unit === unit);
      listLessonByUnit.value = unitInfo.value[currentUnitIndex]?.sequence;
      const availableLessons = unitInfo.value[currentUnitIndex]?.sequence;
      currentLesson.value = unitInfo.value[currentUnitIndex].sequence[availableLessons.length - 1];
    };

    const handleLessonChange = (lesson: any) => {
      currentLesson.value = lesson;
    };

    const handleSubmit = async () => {
      loading.value = true;
      try {
        await dispatch("spin/setSplash", true);
        await dispatch("lesson/setShowPreviewCanvas", false, {
          root: true,
        });
        const unitId = unitInfo.value?.find((unit: UnitAndLesson) => unit.unit === currentUnit.value)?.unitId as number;
        await dispatch("teacherRoom/setLessonAndUnit", {
          unit: currentUnit.value,
          lesson: currentLesson.value,
          unitId,
          isCompleted: isCompleted.value,
        });
        await requestClearBoard();
        commit("annotation/resetState");
        loading.value = false;
        isCompleted.value = false;
        visible.value = false;
      } catch (error) {
        const message = error?.body?.message;
        if (message) {
          messageUpdateLessonAndUnit.value = message;
        }
      }
      loading.value = false;
    };
    const onSubmit = async () => {
      if (getters["classTeaching/isIndependentMode"]) {
        await dispatch("modal/warningIdpModeBeforeExecute", handleSubmit);
      } else {
        await handleSubmit();
      }
    };

    const handleCancel = () => {
      visible.value = false;
    };

    const SetLessonAndUnit = computed(() => fmtMsg(ChangeLessonUnitLocale.SetLessonAndUnit));
    const LessonUnit = computed(() => fmtMsg(DeviceTesterLocale.LessonUnit));
    const Lesson = computed(() => fmtMsg(DeviceTesterLocale.Lesson));
    const Unit = computed(() => fmtMsg(DeviceTesterLocale.Unit));
    const Cancel = computed(() => fmtMsg(DeviceTesterLocale.Cancel));
    const Ok = computed(() => fmtMsg(ChangeLessonUnitLocale.Ok));
    const MarkCurrentLessonCompleteForClass = computed(() => fmtMsg(ChangeLessonUnitLocale.MarkCurrentLessonCompleteForClass));

    return {
      SetLessonAndUnit,
      LessonUnit,
      Lesson,
      Unit,
      Cancel,
      Ok,
      MarkCurrentLessonCompleteForClass,
      visible,
      showModal,
      currentUnit,
      currentLesson,
      listLessonByUnit,
      onSubmit,
      handleCancel,
      preventCloseModal,
      handleUnitChange,
      handleLessonChange,
      unitInfo,
      messageUpdateLessonAndUnit,
      loading,
      classInfo,
      isCompleted,
    };
  },
});
