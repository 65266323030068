import { LoginInfo } from "vue-glcommonui";
import { MutationTree } from "vuex";
import { AuthState } from "./state";

const mutations: MutationTree<AuthState> = {
  setLoginInfo(state: AuthState, payload: LoginInfo) {
    state.loginInfo = payload;
  },

  updateNewToken(state: AuthState, payload: LoginInfo) {
    if (state.loginInfo) {
      state.loginInfo = {
        ...state.loginInfo,
        access_token: payload.access_token,
        expires_at: payload.expires_at,
        id_token: payload.id_token,
        session_state: payload.session_state,
      };
    } else {
      state.loginInfo = payload;
    }
  },
};

export default mutations;
