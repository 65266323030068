import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icon-select.png'


const _hoisted_1 = {
  class: "relative rounded-md overflow-hidden w-canvas shadow",
  oncontextmenu: "return false"
}
const _hoisted_2 = {
  class: "w-full h-canvas bg-white flex items-center justify-center",
  ref: "whiteBoardRef"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["id", "data-slide-id", "volume"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["id", "poster", "data-slide-id", "volume"]
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 5,
  class: "absolute top-2 left-1/2 -translate-x-1/2 text-red-800 text-sm z-40 pointer-events-none"
}
const _hoisted_11 = {
  key: 6,
  class: "absolute top-2.5 left-2.5 helper-interaction-allowed"
}
const _hoisted_12 = { style: {"font-size":"20px","font-weight":"500"} }
const _hoisted_13 = { class: "cursor-pointer" }
const _hoisted_14 = { class: "bg-white border-t border-t-slate-200 text-slate-600 px-6 flex justify-between items-center gap-x-1.5 text-center py-2 text-sm" }
const _hoisted_15 = { class: "flex justify-center items-center gap-x-2 text-center" }
const _hoisted_16 = {
  key: 0,
  class: "flex items-center space-x-2.5"
}
const _hoisted_17 = { class: "flex items-center space-x-1" }
const _hoisted_18 = {
  key: 1,
  class: "flex items-center space-x-2.5 transition-opacity"
}
const _hoisted_19 = { class: "flex items-center space-x-1" }
const _hoisted_20 = {
  key: 0,
  class: "absolute inset-0 cursor-not-allowed helper-interaction-disabled"
}
const _hoisted_21 = {
  key: 0,
  class: "relative"
}
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { key: 1 }
const _hoisted_24 = {
  key: 2,
  class: "absolute inset-0 cursor-not-allowed"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CropImage = _resolveComponent("CropImage")!
  const _component_VuePdfEmbed = _resolveComponent("VuePdfEmbed")!
  const _component_InformationCircleIcon = _resolveComponent("InformationCircleIcon")!
  const _component_Popover = _resolveComponent("Popover")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_ViewfinderCircleIcon = _resolveComponent("ViewfinderCircleIcon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_EyeSlashIcon = _resolveComponent("EyeSlashIcon")!
  const _component_MagnifyingGlassMinusIcon = _resolveComponent("MagnifyingGlassMinusIcon")!
  const _component_MagnifyingGlassPlusIcon = _resolveComponent("MagnifyingGlassPlusIcon")!
  const _component_ToolsCanvas = _resolveComponent("ToolsCanvas")!
  const _component_TextControl = _resolveComponent("TextControl")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          id: "canvas-container",
          class: "w-canvas h-canvas relative flex",
          onMouseover: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleMouseOver && _ctx.handleMouseOver(...args))),
          onMouseout: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleMouseOut && _ctx.handleMouseOut(...args)))
        }, [
          (_ctx.isHelper && _ctx.isPointerMode)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "absolute z-50",
                style: _normalizeStyle([_ctx.pointerStyle])
              }, _cache[13] || (_cache[13] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "",
                  class: "absolute w-full h-full object-contain left-[-45%]"
                }, null, -1)
              ]), 4))
            : _createCommentVNode("", true),
          (_ctx.mediaTypeId === undefined && _ctx.image?.url)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.isCropImage)
                  ? (_openBlock(), _createBlock(_component_CropImage, {
                      imageUrl: _ctx.image.url,
                      metadata: _ctx.image.metaData,
                      canvasImage: _ctx.image,
                      class: "annotation-img",
                      onImgLoad: _ctx.onLPSlideLoaded,
                      ref: "imgCropRef",
                      onImgError: _ctx.onErrorLoadSlide,
                      key: `crop-${_ctx.reloadKey}`
                    }, null, 8, ["imageUrl", "metadata", "canvasImage", "onImgLoad", "onImgError"]))
                  : (_openBlock(), _createElementBlock("img", {
                      crossorigin: "anonymous",
                      ref: "imgRef",
                      src: _ctx.image.url,
                      key: _ctx.reloadKey,
                      onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onErrorLoadSlide && _ctx.onErrorLoadSlide(...args))),
                      alt: "",
                      class: "annotation-img",
                      onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLPSlideLoaded && _ctx.onLPSlideLoaded(...args)))
                    }, null, 40, _hoisted_4))
              ]))
            : (_ctx.mediaTypeId === _ctx.MediaType.pdf && _ctx.image && typeof _ctx.image.url === 'string' && _ctx.isValidUrl)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  style: _normalizeStyle({
            'z-index': _ctx.isWbVisible ? -1 : 200,
          }),
                  class: "absolute inset-0"
                }, [
                  _createVNode(_component_VuePdfEmbed, {
                    source: _ctx.image?.url,
                    class: "w-full h-full object-contain overflow-auto nice-scroll pdf-config",
                    ref: "pdfRef",
                    onRendered: _ctx.onPdfRendered
                  }, null, 8, ["source", "onRendered"])
                ], 4))
              : (_ctx.mediaTypeId === _ctx.MediaType.mp3 && _ctx.image && typeof _ctx.image.url === 'string' && _ctx.isValidUrl)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    style: _normalizeStyle({
            'z-index': _ctx.isWbVisible ? -1 : 200,
          }),
                    class: "absolute object-contain bottom-[5%] left-1/2 -translate-x-1/2"
                  }, [
                    (_ctx.currentAudioBackgroundUrl)
                      ? (_openBlock(), _createElementBlock("img", {
                          crossorigin: "anonymous",
                          ref: "imgRef",
                          src: _ctx.currentAudioBackgroundUrl,
                          class: "annotation-img",
                          onLoad: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onLPSlideLoaded && _ctx.onLPSlideLoaded(...args))),
                          key: _ctx.reloadKey,
                          onError: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onErrorLoadSlide && _ctx.onErrorLoadSlide(...args))),
                          alt: ""
                        }, null, 40, _hoisted_5))
                      : _createCommentVNode("", true),
                    _createElementVNode("audio", {
                      ref: "audio",
                      id: _ctx.ALTERNATE_AUDIO_ID,
                      controls: "",
                      controlslist: "noplaybackrate nodownload",
                      class: "audio-config",
                      onTimeupdate: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onAudioTimeUpdate && _ctx.onAudioTimeUpdate(...args))),
                      "data-slide-id": _ctx.currentExposureItemMedia?.id,
                      volume: _ctx.volume
                    }, [
                      _createElementVNode("source", {
                        src: _ctx.image?.url,
                        type: "audio/mp3"
                      }, null, 8, _hoisted_7),
                      _createTextVNode(" " + _toDisplayString(_ctx.WarningAudioTagText), 1)
                    ], 40, _hoisted_6)
                  ], 4))
                : (_ctx.mediaTypeId === _ctx.MediaType.mp4 && _ctx.image && typeof _ctx.image.url === 'string' && _ctx.isValidUrl)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 4,
                      style: _normalizeStyle({
            'z-index': _ctx.isWbVisible ? -1 : 200,
          }),
                      class: "absolute inset-0 flex justify-center items-center bg-white"
                    }, [
                      _createElementVNode("video", {
                        ref: "video",
                        id: _ctx.ALTERNATE_VIDEO_ID,
                        controls: "",
                        controlslist: "noplaybackrate nodownload",
                        class: "video-config",
                        oncontextmenu: "return false",
                        poster: _ctx.image?.thumbnail,
                        "data-slide-id": _ctx.currentExposureItemMedia?.id,
                        volume: _ctx.volume
                      }, [
                        _createElementVNode("source", {
                          src: _ctx.image?.url,
                          type: "video/mp4"
                        }, null, 8, _hoisted_9),
                        _createTextVNode(" " + _toDisplayString(_ctx.WarningVideoTagText), 1)
                      ], 8, _hoisted_8)
                    ], 4))
                  : _createCommentVNode("", true),
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "relative z-20" }, [
            _createElementVNode("canvas", {
              class: "canvas-designate",
              id: "canvasDesignate"
            })
          ], -1)),
          (_ctx.isTeacherUseOnly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.forTeacherUseOnlyText), 1))
            : _createCommentVNode("", true),
          (_ctx.focusWordContent.condition)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_Popover, { placement: "rightTop" }, {
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.focusWordContent.word), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.focusWordContent.definition), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_InformationCircleIcon, { class: "w-6 h-6 text-gs-purple" })
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 32)
      ], 512), [
        [_vShow, !_ctx.isWhiteboardShrink]
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("span", null, _toDisplayString(_ctx.ShowWhiteboardText), 1),
          _createVNode(_component_Switch, {
            checked: _ctx.isWbVisible,
            onClick: _cache[7] || (_cache[7] = 
            () => {
              _ctx.isWbVisible ? _ctx.hideWhiteboard() : _ctx.showWhiteboard();
            }
          ),
            class: _normalizeClass([
            _ctx.isWbVisible ? 'bg-gs-purple' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gs-purple focus:ring-offset-2',
          ])
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                "aria-hidden": "true",
                class: _normalizeClass([
              _ctx.isWbVisible ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            ])
              }, null, 2)
            ]),
            _: 1
          }, 8, ["checked", "class"])
        ]),
        (_ctx.image && _ctx.image.metaData && !_ctx.isWbVisible && _ctx.hasTargets)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("span", null, _toDisplayString(_ctx.targetText) + " " + _toDisplayString(_ctx.targetsNum), 1),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_Tooltip, { title: _ctx.PreviewTargetText }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass([[_ctx.disablePreviewBtn ? 'bg-slate-50 text-slate-300' : 'bg-slate-100 text-slate-600 cursor-pointer hover:bg-white hover:shadow'], "h-6 w-6 rounded-lg bg-slate-100 flex justify-center items-center"]),
                      onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.showHidePreviewModal && _ctx.showHidePreviewModal(...args)))
                    }, [
                      _createVNode(_component_ViewfinderCircleIcon, { class: "h-4 w-4" })
                    ], 2)
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_Tooltip, { title: _ctx.MsgShowAllTargetsBtn }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass([
                'h-6 w-6 rounded-lg flex justify-center items-center',
                _ctx.isAllTargetsOnCanvasVisible ? 'bg-slate-50 text-slate-300' : 'bg-slate-100 text-slate-600 cursor-pointer hover:bg-white hover:shadow',
              ]),
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleClickToggleAllTargetsBtn(true)))
                    }, [
                      _createVNode(_component_EyeIcon, { class: "h-4 w-4" })
                    ], 2)
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_Tooltip, { title: _ctx.MsgHideAllTargetsBtn }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass([
                'h-6 w-6 rounded-lg bg-slate-100 flex justify-center items-center',
                _ctx.isAllTargetsOnCanvasTransparent
                  ? 'bg-slate-50 text-slate-300'
                  : 'bg-slate-100 text-slate-600 cursor-pointer hover:bg-white hover:shadow',
              ]),
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.handleClickToggleAllTargetsBtn(false)))
                    }, [
                      _createVNode(_component_EyeSlashIcon, { class: "h-4 w-4" })
                    ], 2)
                  ]),
                  _: 1
                }, 8, ["title"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.isWbVisible || (!_ctx.video && !_ctx.pdfRef))
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("span", null, _toDisplayString(_ctx.zoomPercentage) + "%", 1),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", {
                  class: "h-6 w-6 rounded-lg bg-slate-100 hover:bg-white hover:shadow flex justify-center items-center",
                  onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.zoomOut && _ctx.zoomOut(...args)))
                }, [
                  _createVNode(_component_MagnifyingGlassMinusIcon, { class: "cursor-pointer h-4 w-4" })
                ]),
                _createElementVNode("div", {
                  class: "h-6 w-6 rounded-lg bg-slate-100 hover:bg-white hover:shadow flex justify-center items-center",
                  onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.zoomIn && _ctx.zoomIn(...args)))
                }, [
                  _createVNode(_component_MagnifyingGlassPlusIcon, { class: "cursor-pointer h-4 w-4" })
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isHelper)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isHelper && !_ctx.isWhiteboardShrink)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          (!_ctx.isTextBoxSelected)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createVNode(_component_ToolsCanvas, {
                  "tool-selected": _ctx.toolSelected,
                  "stroke-width": _ctx.strokeWidth,
                  "stroke-color": _ctx.strokeColor,
                  onToolSelected: _ctx.clickedTool,
                  onUpdateColor: _ctx.updateColorValue,
                  onUpdateStroke: _ctx.updateStrokeWidth
                }, null, 8, ["tool-selected", "stroke-width", "stroke-color", "onToolSelected", "onUpdateColor", "onUpdateStroke"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createVNode(_component_TextControl, {
                  onCloseTextControl: _ctx.closeTextBoxControl,
                  controlTextState: _ctx.controlTextState,
                  onUpdateControlTextState: _ctx.updateControlTextState
                }, null, 8, ["onCloseTextControl", "controlTextState", "onUpdateControlTextState"])
              ])),
          (_ctx.isHelper)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}