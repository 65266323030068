<template>
  <div class="page-container text-slate-600">
    <img src="@/assets/images/access-denied.png" alt="" />
    <h1>{{ accessDeniedWeAreSorry }}</h1>
    <p>{{ accessDeniedDescription }}</p>
    <p>{{ accessDeniedSuggest }}</p>
  </div>
</template>
<style lang="scss" scoped src="./access-denied.scss"></style>
<script lang="ts" src="./access-denied.ts"></script>
