import { AuthService, fmtMsg } from "vue-glcommonui";
import { ForceLogoutLocale, LoadingLocale } from "@/locales/localeid";
import { computed, defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";
import { ForceLogoutCodes } from "@/utils/utils";

export default defineComponent({
  setup() {
    const titleLoadingText = computed(() => fmtMsg(LoadingLocale.Title));
    const timeoutId: Ref<NodeJS.Timeout | null> = ref(null);

    const MsgConfirm = computed(() => fmtMsg(ForceLogoutLocale.Confirm, { code: ForceLogoutCodes.SplashFreeze }));

    const logout = () => {
      AuthService.storePagethenSignoutRedirect();
    };

    onMounted(() => {
      timeoutId.value = setTimeout(() => {
        alert(MsgConfirm.value);
        logout();
      }, 60 * 1000); // 1 minutes
    });

    onUnmounted(() => {
      if (timeoutId.value) {
        clearTimeout(timeoutId.value);
      }
    });
    return {
      titleLoadingText,
    };
  },
});
