import { brushstrokesRender } from "@/components/common/annotation-view/components/brush-strokes";
import { renderPolylineAsLaserOnCanvas } from "@/components/common/annotation-view/components/laser-path";
import { useFabricObject } from "@/hooks/use-fabric-object";
import { usePointerStyle } from "@/hooks/use-pointer-style";
import { useWhiteboardMediaState } from "@/hooks/use-whiteboard-media-state";
import { TeacherClassLocale } from "@/locales/localeid";
import { MediaType, TeacherModel } from "@/models";
import { FabricUpdateType, LastFabricUpdated, UserShape } from "@/store/annotation/state";
import { ExposureItem, ExposureItemMedia } from "@/store/lesson/state";
import {
  ALTERNATE_AUDIO_ID,
  ALTERNATE_VIDEO_ID,
  DEFAULT_ALTERNATE_CONTENT_VOLUME_FOR_STUDENT,
  DefaultCanvasDimension,
  isRotatedLPSlide,
} from "@/utils/utils";
import { Alert, Popover } from "ant-design-vue";
import { fabric } from "fabric";
import { computed, defineComponent, nextTick, onMounted, ref, watch, watchEffect } from "vue";
import { fmtMsg, LoginInfo } from "vue-glcommonui";
import VuePdfEmbed from "vue-pdf-embed";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { renderPolylineAsLineOnCanvas } from "./components/pencil-path";
import { useElementSize, watchDebounced } from "@vueuse/core";
import { useFocusWord } from "@/hooks/use-focus-word";
import { useWhiteboard } from "@/hooks/use-whiteboard";
import { initCanvas } from "@/hooks/use-canvas";
import { useCanvasObjectUpdate } from "@/hooks/use-canvas-object-update";
import { useHandleObjects } from "@/hooks/use-handle-objects";
import { useObjectState } from "@/hooks/use-object-state";
import { useImageLoader } from "@/hooks/use-image-loader";
import { useTarget } from "@/hooks/use-target";
import * as slideUtils from "@/utils/slide-utils";
import { vuexName, VuexNames } from "@/store/utils";
import { StudentState } from "@/store/room/interface";

export default defineComponent({
  props: ["image", "id"],
  components: {
    Popover,
    VuePdfEmbed,
    Alert,
  },
  setup(props) {
    let canvas: any;
    const route = useRoute();
    const { studentId } = route.params;
    const { getters, dispatch, commit } = useStore();
    const { videoRef, audioRef, toggleMediaCanPlay } = useWhiteboardMediaState();
    const { lastUpdatedFabricObject, lastDeletedFabricObjectId, lines, linesOne, shapes, shapesOne, texts } = useObjectState();
    useCanvasObjectUpdate();
    const { resetReloadKey, reloadKey, handleImageLoadError } = useImageLoader(false);
    const { initializeTargets } = useTarget();
    const { removeTextBoxObjectsFromCanvas } = useHandleObjects();
    const { isWbVisible } = useWhiteboard();
    const containerRef = ref<HTMLDivElement>();
    const { pointerStyle, scaleRatio } = usePointerStyle(containerRef);
    const currentExposure = computed(() => getters["lesson/currentExposure"]);
    const currentExposureItemMedia = computed<ExposureItemMedia | undefined>(() => getters["lesson/currentExposureItemMedia"]);
    const { width: containerWidth } = useElementSize(containerRef);
    const isPointerMode = computed(() => getters["annotation/isPointerMode"]);
    const WarningAudioTagText = computed(() => fmtMsg(TeacherClassLocale.WarningAudioTag));
    const WarningVideoTagText = computed(() => fmtMsg(TeacherClassLocale.WarningVideoTag));
    const isTeacherUseOnly = computed<boolean>(() => !!currentExposureItemMedia.value?.teacherUseOnly && !isWbVisible.value);
    const imgCropRef = ref<any>(null);
    const imgRef = ref(null);
    const boardImageUrl = ref();
    const isBoardImageFetching = ref(false);
    const boardImageLoadingTimeoutId = ref();
    const boardImageLoadingThreshold = 300;
    const { focusWordContent } = useFocusWord();
    const undoStrokeOneOne = computed(() => getters["annotation/undoStrokeOneOne"]);
    const videoAnnotation = ref<HTMLVideoElement | null>(null);
    const audioAnnotation = ref<HTMLVideoElement | null>(null);
    const undoCanvas = computed(() => getters["annotation/undoShape"]);
    const laserPath = computed(() => getters["annotation/laserPath"]);
    const pencilPath = computed(() => getters["annotation/pencilPath"]);
    const student = computed<StudentState | undefined>(() => getters["studentRoom/student"]);
    const oneToOneStudentId = computed(() => getters[vuexName(VuexNames.STUDENT_ROOM.GETTERS.GET_ONE_TO_ONE_STUDENT_ID)]);
    const teacherForST = computed<TeacherModel>(() => getters["studentRoom/teacher"]);
    const zoomRatio = computed(() => getters["lesson/zoomRatio"]);
    const imgCoords = computed(() => getters["lesson/imgCoords"]);
    const isPaletteVisible = computed(
      () => (student.value?.isPalette && !oneToOneStudentId.value) || (student.value?.isPalette && student.value?.id == oneToOneStudentId.value),
    );
    const mediaState = computed(() => getters["studentRoom/getMediaState"]);
    const teacherDisconnected = computed<boolean>(() => getters["studentRoom/teacherIsDisconnected"]);
    const oneOneIdNear = ref<string>(oneToOneStudentId.value ?? "");
    let group: any;
    let point: any;
    const prevZoomRatio = ref(1);
    const prevCoords = ref({ x: 0, y: 0 });
    const prevZoomRatioByTeacher = ref(1);
    const pdfRef = ref<InstanceType<typeof VuePdfEmbed>>();
    /** This function is going to reset the state of board */
    const clearTheBoard = () => {
      /** Reset audio background state */
      audioLocalTime.value = 0;
    };
    watch(
      () => props.image?.url,
      () => {
        videoAnnotation.value?.load();
        audioAnnotation.value?.load();
      },
    );

    const isValidUrl = computed(() => props.image?.url && props.image?.url !== "default");

    const transformCanvas = () => {
      canvas?.setViewportTransform([
        scaleRatio.value * zoomRatio.value,
        0,
        0,
        scaleRatio.value * zoomRatio.value,
        (imgCoords.value?.x ?? 0) * scaleRatio.value,
        (imgCoords.value?.y ?? 0) * scaleRatio.value,
      ]);
    };

    watch(
      zoomRatio,
      () => {
        transformCanvas();
      },
      { immediate: true },
    );
    watch(imgCoords, (currentValue) => {
      if (currentValue) {
        transformCanvas();
      }
      canvas?.renderAll();
    });

    const { displayFabricItems, displayCreatedItem, displayModifiedItem, onObjectCreated, FontLoader, onObjectRemoved } = useFabricObject();
    const mediaTypeId = computed(() => {
      const nextId = getters["lesson/currentExposureItemMedia"]?.id;
      if (!nextId) return;
      let result;
      const listMedia = currentExposure.value?.alternateMediaBlockItems.flat();
      if (listMedia) {
        const target = listMedia.find(({ id }: ExposureItem) => {
          return nextId === id;
        });
        if (target) {
          result = target.mediaTypeId;
        }
      }
      return result;
    });
    watch(
      [mediaTypeId, currentExposureItemMedia],
      async ([curMediaTypeId, curExposureItem]) => {
        if (typeof curMediaTypeId === "number" && curExposureItem?.image?.url === "default") {
          const loginInfo: LoginInfo = getters["auth/getLoginInfo"];
          await dispatch("lesson/getAlternateMediaUrl", {
            token: loginInfo.access_token,
            id: curExposureItem.id,
          });
        }
      },
      { immediate: true },
    );
    watch(currentExposureItemMedia, async (currentItem, prevItem) => {
      if (
        slideUtils.isTeacherNotChooseAnySlide(currentItem) ||
        slideUtils.isTeacherChooseTeacherUseOnlySlide(currentItem) ||
        slideUtils.isTeacherChangedFromCurrentSlideToOtherSlideOrNoSlide(currentItem, prevItem)
      ) {
        canvas.remove(group);
      }
      if (currentItem) {
        transformCanvas();
      }
      if (currentItem && prevItem && currentItem.image.url === prevItem.image.url) {
        await onLPSlideLoaded();
      }
      clearTheBoard();
    });
    const processCanvasWhiteboard = () => {
      if (isWbVisible.value) {
        canvas.remove(...canvas.getObjects().filter((obj: any) => obj.id === "annotation-lesson" && obj.id !== "lesson-img"));
        canvas.setBackgroundColor("white", canvas.renderAll.bind(canvas));
        if (group) {
          group.visible = false;
        }
      } else {
        canvas.setBackgroundColor("transparent", canvas.renderAll.bind(canvas));
        canvas.isDrawingMode = false;
        if (group) {
          group.visible = true;
        }
      }
      canvas.renderAll();
    };
    watch(isWbVisible, () => {
      processCanvasWhiteboard();
    });
    const undoStrokeByTeacher = () => {
      if (undoCanvas.value) {
        canvas.remove(...canvas.getObjects().filter((obj: any) => obj.type === "path" || obj.type === "polyline"));
      }
    };
    const undoStrokeByTeacherOneOne = () => {
      if (undoStrokeOneOne.value) {
        canvas.remove(...canvas.getObjects().filter((obj: any) => obj.type === "path"));
      }
    };
    const renderStrokes = (data: any, oneId: any) => {
      data.forEach((s: any) => {
        new fabric.Path.fromObject(JSON.parse(s), (item: any) => {
          item.isOneToOne = oneId;
          item.id = teacherForST.value?.id;
          canvas.add(item);
        });
      });
      objectCanvasProcess();
    };
    const removeAndReRenderPaths = () => {
      if (lines.value && (!oneToOneStudentId.value || oneToOneStudentId.value != studentId)) {
        undoStrokeByTeacher();
        renderStrokes(lines.value, null);
      }
    };
    watch(lines, async () => {
      await nextTick();
      if (!pencilPath.value.length || !lines.value.length) {
        removeAndReRenderPaths();
      }
    });
    watch(
      laserPath,
      () => {
        renderPolylineAsLaserOnCanvas({ laserPath, canvas, oneToOneStudentId: oneToOneStudentId.value, student: student.value });
      },
      { deep: true },
    );

    const teacherSharingShapes = (dataShapes: Array<UserShape>, studentOneId: string | null) => {
      if (dataShapes && dataShapes.length) {
        canvas.remove(...canvas.getObjects().filter((obj: any) => obj.type === "rect" || obj.type === "circle"));
        dataShapes.forEach((item) => {
          brushstrokesRender(canvas, item, studentOneId, "teacher-shapes");
        });
      } else {
        canvas.remove(...canvas.getObjects().filter((obj: any) => obj.tag === "teacher-shapes"));
      }
    };
    watch(
      shapes,
      () => {
        teacherSharingShapes(shapes.value, null);
      },
      { deep: true },
    );
    const renderOneTeacherStrokes = () => {
      if (linesOne.value && linesOne.value.length > 0 && oneToOneStudentId.value === studentId) {
        renderStrokes(linesOne.value, oneToOneStudentId.value);
      }
    };
    watch(linesOne, () => {
      if (!pencilPath.value.length || !linesOne.value.length) {
        if (oneToOneStudentId.value === studentId) {
          undoStrokeByTeacherOneOne();
          renderOneTeacherStrokes();
        }
      }
    });
    const renderOneTeacherShapes = () => {
      if (oneToOneStudentId.value === studentId) {
        teacherSharingShapes(shapesOne.value, oneToOneStudentId.value);
      }
    };
    watch(
      shapesOne,
      () => {
        renderOneTeacherShapes();
      },
      { deep: true },
    );
    watch(oneToOneStudentId, async (curVal, prevVal) => {
      if (curVal) {
        if (curVal !== studentId) return;
        oneOneIdNear.value = oneToOneStudentId.value;
        prevZoomRatio.value = canvas.getZoom();
        prevZoomRatioByTeacher.value = zoomRatio.value;
        if (group) {
          prevCoords.value = {
            x: group.left,
            y: group.top,
          };
        }
        processCanvasWhiteboard();
        if (oneToOneStudentId.value !== studentId) {
          // disable shapes of student not 1-1
          canvas.isDrawingMode = false;
          canvas.discardActiveObject();
          canvas
            .getObjects()
            .filter((obj: any) => obj.type !== "path" && obj.type !== "group")
            .filter((obj: any) => obj.id !== oneToOneStudentId.value)
            .forEach((item: any) => {
              item.selectable = false;
              item.hasControls = false;
              item.hasBorders = false;
              item.hoverCursor = "cursor";
            });
        }
      } else {
        if (prevVal !== studentId) return;
        if (studentId === oneOneIdNear.value) {
          canvas.remove(...canvas.getObjects().filter((obj: any) => obj.isOneToOne !== null && obj.id !== "lesson-img"));
          if (!pencilPath.value.length) {
            undoStrokeByTeacher();
          }
          // render shapes objects again
          processCanvasWhiteboard();
          setTimeout(() => {
            teacherSharingShapes(shapes.value, null);
            oneOneIdNear.value = "";
          }, 800);
          await dispatch("lesson/setZoomRatio", prevZoomRatioByTeacher.value, { root: true });
        }
        await dispatch("annotation/clearPencilPath");
      }
    });
    const listenToCanvasEvents = () => {
      onObjectCreated(canvas);
      onObjectRemoved(canvas);
    };
    const canvasRef = ref(null);
    const initWhiteboard = async () => {
      if (!canvasRef.value) return;
      const { width, height } = DefaultCanvasDimension;
      canvas = new fabric.Canvas(canvasRef.value, {
        width,
        height,
      });
      initCanvas(canvas);
      canvas.preserveObjectStacking = true;
      canvas.selectionFullyContained = false;
      canvas.getObjects("path").forEach((obj: any) => {
        obj.selectable = false;
        obj.evented = false;
        obj.perPixelTargetFind = true;
      });
      await FontLoader.load();
      listenToCanvasEvents();
      resizeCanvas();
      processCanvasWhiteboard();
    };
    const isImgProcessing = computed(() => getters["annotation/isImgProcessing"]);
    const isCropImage = computed(() => !!props.image?.metaData && props.image?.metaData.width > 0 && props.image?.metaData.height > 0);
    const initializeSlideOnCanvas = ({
      propImage,
      canvas,
      imgEl,
      isShowWhiteBoard,
    }: {
      propImage: any;
      canvas: any;
      imgEl: any;
      isShowWhiteBoard: boolean;
    }) => {
      if (!propImage?.url) {
        return;
      }
      const isRotatedSlide = isRotatedLPSlide(propImage?.metaData);
      const imgWidth = isRotatedSlide ? getters["annotation/imgHeight"] : getters["annotation/imgWidth"];
      const imgHeight = isRotatedSlide ? getters["annotation/imgWidth"] : getters["annotation/imgHeight"];
      const canvasGroup = canvas.getObjects().find((item: any) => item.id === "lesson-img");
      const imageRatio = Math.max(imgWidth / DefaultCanvasDimension.width, imgHeight / DefaultCanvasDimension.height);
      const renderWidth = imgWidth / imageRatio;
      const renderHeight = imgHeight / imageRatio;
      if (canvasGroup) {
        canvas.remove(canvasGroup);
      }
      const clipPath = new fabric.Rect({
        width: renderWidth,
        height: renderHeight,
        left: (DefaultCanvasDimension.width - renderWidth) / 2,
        top: 0,
        absolutePositioned: true,
      });
      const angle = propImage.metaData ? (propImage.metaData.width > 0 && propImage.metaData.height > 0 ? 0 : propImage.metaData.rotate) : 0;
      const Image = new fabric.Image(imgEl, {
        id: "lesson-img",
        clipPath,
        originX: "center",
        originY: "center",
        angle,
        selectable: false,
        hasBorders: false,
        hasControls: false,
      });
      Image.scaleToWidth(renderWidth);
      Image.scaleToHeight(renderHeight);
      const left = DefaultCanvasDimension.width / 2;
      const top = renderHeight / 2;

      const Group = new fabric.Group([Image], {
        id: "lesson-img",
        clipPath,
        originX: "center",
        originY: "center",
        left,
        top,
        selectable: isPaletteVisible.value,
        visible: !isShowWhiteBoard,
        hoverCursor: "pointer",
        scaleX: propImage?.metaData?.scaleX ?? 1,
        scaleY: propImage?.metaData?.scaleY ?? 1,
        hasBorders: false,
        hasControls: false,
        layout: "clip-path",
        interactive: true,
        subTargetCheck: true,
        lockMovementX: true,
        lockMovementY: true,
      });
      if (!isImgProcessing.value) {
        if (top !== Group.top) {
          Group.realTop = Group.top;
        }
        if (left !== Group.left) {
          Group.realLeft = Group.left;
        }
        canvas.add(Group);
        canvas.sendToBack(Group);
        transformCanvas();
      }
      return Group;
    };
    const onLPSlideLoaded = async (e?: Event) => {
      if (e) toggleImageFetchingSpin(false);
      let img: HTMLImageElement | null;
      if (e) {
        img = e?.target as HTMLImageElement;
      } else {
        if (isCropImage.value) {
          img = imgCropRef.value?.imageRef;
        } else {
          img = imgRef.value;
        }
      }
      if (!img) return;
      if (img.naturalWidth && img.naturalHeight) {
        await dispatch("annotation/setImgDimension", {
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      } else {
        await dispatch("annotation/setImgDimension", {
          width: undefined,
          height: undefined,
        });
      }
      img.crossOrigin = "anonymous";
      if (!isImgProcessing.value) {
        group = initializeSlideOnCanvas({
          propImage: props.image,
          canvas,
          imgEl: img,
          isShowWhiteBoard: isWbVisible.value,
        });
        if (props.image?.metaData?.annotations) {
          initializeTargets(props.image.metaData);
        }
      }
    };
    watchDebounced(
      containerWidth,
      (value) => {
        if (value) {
          resizeCanvas();
          zoomRatio.value && point && canvas.zoomToPoint(point, zoomRatio.value * scaleRatio.value);
          pdfRef.value?.render();
        }
      },
      { debounce: 300 },
    );
    const resizeCanvas = () => {
      const outerCanvasContainer = containerRef.value;
      if (!outerCanvasContainer || !outerCanvasContainer.clientWidth) return;
      const { width, height } = DefaultCanvasDimension;
      const ratio = width / height;
      const containerWidth = outerCanvasContainer.clientWidth;
      scaleRatio.value = containerWidth / width;
      canvas.setDimensions({
        width: containerWidth,
        height: containerWidth / ratio,
      });
      transformCanvas();
      point = new fabric.Point(containerWidth / 2, containerWidth / ratio / 2);
    };
    const objectCanvasProcess = () => {
      canvas.getObjects().forEach((obj: any) => {
        if (obj.type === "path") {
          obj.selectable = false;
          obj.evented = false;
          obj.hasControls = false;
          obj.hasBorders = false;
          obj.hoverCursor = "cursor";
          obj.perPixelTargetFind = true;
        }
      });
    };
    watch(texts, async (val) => {
      removeTextBoxObjectsFromCanvas();
      displayFabricItems(canvas, val);
    });
    watch(lastDeletedFabricObjectId, async (mustDelId) => {
      if (mustDelId) canvas.remove(...canvas.getObjects().filter((obj: any) => obj.objectId === mustDelId));
      commit("annotation/setLastFabricIdDeleted", null, { root: true });
    });
    const getFabricItemById = (id: string) => canvas.getObjects().find((obj: any) => obj.objectId === id);
    //receive the object lastUpdatedFabricObject and update the whiteboard
    watch(lastUpdatedFabricObject, async (mustUpdateItem: LastFabricUpdated) => {
      if (!mustUpdateItem) return;
      const { type, data } = mustUpdateItem;
      switch (type) {
        case FabricUpdateType.CREATE: {
          displayCreatedItem(canvas, data);
          await dispatch("annotation/setFabricObjects", data);
          break;
        }
        case FabricUpdateType.MODIFY: {
          const existingObject = getFabricItemById(data.fabricId);
          if (!existingObject) {
            displayCreatedItem(canvas, data);
            await dispatch("annotation/setFabricObjects", data);
            break;
          }
          await dispatch("annotation/setFabricObjects", data);
          displayModifiedItem(canvas, data, existingObject);
          break;
        }
        default:
          break;
      }
      await dispatch("annotation/setLastFabricUpdated", null, { root: true });
    });
    watch(
      mediaState,
      () => {
        if (videoAnnotation.value) {
          mediaState.value === true ? videoAnnotation.value.play() : videoAnnotation.value.pause();
        }
        if (audioAnnotation.value) {
          mediaState.value === true ? audioAnnotation.value.play() : audioAnnotation.value.pause();
        }
      },
      { deep: true },
    );

    watch(videoAnnotation, (currentValue) => {
      videoRef.value = currentValue;
    });
    watch(audioAnnotation, (currentValue) => {
      audioRef.value = currentValue;
    });

    watch(
      pencilPath,
      () => {
        renderPolylineAsLineOnCanvas(pencilPath.value, canvas, oneToOneStudentId.value, student.value);
      },
      { deep: true },
    );
    onMounted(async () => {
      await initWhiteboard();
    });

    // render pdf
    const pdfHeight = ref<number[]>([]);
    const onPdfRendered = () => {
      pdfHeight.value = [];
      document.querySelectorAll(".pdf-config canvas").forEach((element) => {
        pdfHeight.value.push(element.clientHeight);
      });
      if (pdfScrollProgress.value) {
        scrollPdfToPercentage(pdfScrollProgress.value, false);
      }
    };

    // when teacher scrolls pdf, update student's side
    const pdfScrollProgress = computed(() => getters["lesson/pdfScrollProgress"]);
    const scrollPdfToPercentage = (percentage: number, isSmooth = true) => {
      const pdfElement = document.querySelector(".pdf-config");
      let currentPage = 0;
      let scrollHeight = 0;
      while (percentage > 0) {
        if (percentage >= 100) {
          scrollHeight += pdfHeight.value[currentPage];
          percentage -= 100;
          currentPage++;
        } else {
          scrollHeight += (pdfHeight.value[currentPage] * percentage) / 100;
          percentage = 0;
          currentPage++;
        }
      }
      pdfElement?.scrollTo({
        top: scrollHeight,
        behavior: isSmooth ? "smooth" : "auto",
      });
    };
    watch(pdfScrollProgress, (progress) => scrollPdfToPercentage(progress));

    // handle audio background
    const audioLocalTime = ref(0); // audio's actual current time, not from state, in seconds
    const onAudioTimeUpdate = () => {
      audioLocalTime.value = audioAnnotation.value?.currentTime ?? 0;
    };

    const audioBackground = computed(() => {
      const backgrounds = props.image?.audioBackground;
      if (mediaTypeId.value !== MediaType.mp3 || !backgrounds || !backgrounds.length) {
        return undefined;
      }
      return [...backgrounds];
    });

    const currentAudioBackgroundUrl = computed(() => {
      if (!audioBackground.value) {
        return undefined;
      }
      const currentBackground =
        audioBackground.value?.find((media) => media?.pageTimingToMillisecond && media.pageTimingToMillisecond > audioLocalTime.value * 1000) ??
        audioBackground.value?.[audioBackground.value?.length - 1];
      return currentBackground.image?.url;
    });

    const toggleImageFetchingSpin = (visible: boolean) => {
      if (boardImageLoadingTimeoutId.value) clearTimeout(boardImageLoadingTimeoutId.value);
      if (visible && !isTeacherUseOnly.value) {
        boardImageLoadingTimeoutId.value = setTimeout(() => {
          isBoardImageFetching.value = true;
        }, boardImageLoadingThreshold);
      } else {
        if (isBoardImageFetching.value) isBoardImageFetching.value = false;
      }
    };
    const resetBoardImageLoadingRefs = () => {
      resetReloadKey();
      if (isBoardImageFetching.value) isBoardImageFetching.value = false;
      if (boardImageLoadingTimeoutId.value) clearTimeout(boardImageLoadingTimeoutId.value);
    };

    watch(boardImageUrl, (val) => {
      resetBoardImageLoadingRefs();
      if (val) toggleImageFetchingSpin(true);
    });

    //** This watch handle specific case when switch from crop -> normal image, both have same URL */
    watch(isCropImage, (val) => {
      if (!val) toggleImageFetchingSpin(true);
    });

    watchEffect(() => {
      if (mediaTypeId.value === undefined) {
        boardImageUrl.value = props.image?.url;
      } else if (mediaTypeId.value === MediaType.mp3) {
        boardImageUrl.value = currentAudioBackgroundUrl.value;
      } else {
        boardImageUrl.value = undefined;
      }
    });

    watch(
      () => props.image?.url,
      async () => {
        // Run before old videoRef/audioRef is removed
        if (videoAnnotation.value) {
          videoAnnotation.value.oncanplay = null;
        }
        if (audioAnnotation.value) {
          audioAnnotation.value.oncanplay = null;
        }
        toggleMediaCanPlay(false);
        await nextTick();
        // Run after new videoRef/audioRef is created
        if (videoAnnotation.value) {
          videoRef.value = videoAnnotation.value;
          videoAnnotation.value.onloadstart = setSinkId;
          videoAnnotation.value.oncanplay = () => {
            toggleMediaCanPlay(true);
          };
        }
        if (audioAnnotation.value) {
          audioRef.value = audioAnnotation.value;
          audioAnnotation.value.onloadstart = setSinkId;
          audioAnnotation.value.oncanplay = () => {
            toggleMediaCanPlay(true);
          };
        }
      },
    );

    const onErrorLoadSlide = () => {
      canvas.getObjects().forEach((obj: any) => {
        if (obj.type === "group") {
          canvas.remove(obj);
        }
      });
      handleImageLoadError();
    };

    const speakerDeviceId = computed(() => getters["speakerDeviceId"]);

    const setSinkId = async () => {
      if (mediaTypeId.value === MediaType.mp4) {
        const videoEl = document.getElementById(ALTERNATE_VIDEO_ID) as HTMLVideoElement;
        if (typeof videoEl?.setSinkId === "function") {
          await videoEl?.setSinkId(speakerDeviceId.value);
        }
      } else if (mediaTypeId.value === MediaType.mp3) {
        const audioEl = document.getElementById(ALTERNATE_AUDIO_ID) as HTMLAudioElement;
        if (typeof audioEl?.setSinkId === "function") {
          await audioEl?.setSinkId(speakerDeviceId.value);
        }
      }
    };

    watch(speakerDeviceId, setSinkId);

    return {
      containerRef,
      containerWidth,
      pointerStyle,
      mediaTypeId,
      isPointerMode,
      canvasRef,
      isWbVisible,
      student,
      oneToOneStudentId,
      onLPSlideLoaded,
      videoAnnotation,
      audioAnnotation,
      isValidUrl,
      isCropImage,
      imgCropRef,
      imgRef,
      focusWordContent,
      onPdfRendered,
      currentExposureItemMedia,
      onAudioTimeUpdate,
      currentAudioBackgroundUrl,
      MediaType,
      WarningAudioTagText,
      WarningVideoTagText,
      pdfRef,
      isBoardImageFetching,
      onErrorLoadSlide,
      boardImageUrl,
      reloadKey,
      teacherDisconnected,
      DEFAULT_ALTERNATE_CONTENT_VOLUME_FOR_STUDENT,
      ALTERNATE_VIDEO_ID,
      ALTERNATE_AUDIO_ID,
    };
  },
});
