import { AgoraClient, AgoraEventHandler } from "@/agora";
import { store } from "@/store";
import { TeacherWSClient } from "@/ws";
import { BaseRoomManager, RoomOptions } from "./base.manager";
import { RoomModel } from "@/models";
import { LoginInfo, RoleName } from "vue-glcommonui";
import { HelperState, TeacherState } from "@/store/room/interface";
import { isUserPublishedAudio, isUserPublishedVideo } from "@/store/calling/utils";

export class TeacherRoomManager extends BaseRoomManager<TeacherWSClient> {
  constructor(options: RoomOptions) {
    super();
    this.options = options;
    if (options.agora) {
      this.agoraClient = new AgoraClient(options.agora);
    }
    this.WSClient = new TeacherWSClient({
      url: `${process.env.VUE_APP_REMOTE_TEACHING_SERVICE}/${process.env.VUE_APP_REMOTE_TEACHING_HUB}`,
      reConnectedCallback: async (newConnectionId: string) => {
        await this.handleSuccessfulReconnection(newConnectionId);
      },
    });
    this.WSClient.init();
  }

  async handleSuccessfulReconnection(newConnectionId: string) {
    await store.dispatch("teacherRoom/setSignalRConnectionId", newConnectionId);
    await store.dispatch("teacherRoom/joinWSRoom", true);
    const refreshSessionData = async () => {
      const { getters, dispatch } = store;
      const room = getters["teacherRoom/info"] as RoomModel;
      const { groupId, deviceId } = getters["teacherRoom/getParamsToJoinCurSession"];
      const loginInfo: LoginInfo = getters["auth/getLoginInfo"];
      const isHelper = store.getters["teacher/isHelper"];
      await dispatch("teacherRoom/initClassRoom", {
        classId: room.classInfo.classId,
        userId: loginInfo.profile.sub,
        userName: loginInfo.profile.name,
        role: RoleName.teacher,
        deviceId,
        isHelper,
        groupId,
        callFirstTime: false,
      });
    };
    await refreshSessionData();
  }

  async join(options: { classId?: string; studentId?: string; teacherId?: string; idOne?: string; callingEventHandlers: AgoraEventHandler | null }) {
    if (!options.teacherId || !options.classId) throw new Error("Missing Params");
    await this.WSClient.connect();
    await this.agoraClient.joinRTCRoom({ ...options, videoEncoderConfigurationPreset: "480p" }, false, async () => {
      await this.reconnectAgoraRoom();
    });
  }

  async reconnectAgoraRoom() {
    if (!this.agoraClient.joinRoomOptions) return;
    const joinAsHelper = store.getters["teacher/isHelper"];
    const deviceStatus = {
      camera: false,
      microphone: false,
    };
    if (joinAsHelper) {
      const helper: HelperState | undefined = store.getters["teacherRoom/helperInfo"];
      const isVideoHelperPublished = isUserPublishedVideo({ userId: helper?.id ?? "", gettersOrRootGetters: store.getters });
      const isAudioHelperPublished = isUserPublishedVideo({ userId: helper?.id ?? "", gettersOrRootGetters: store.getters });
      deviceStatus.camera = isVideoHelperPublished ?? false;
      deviceStatus.microphone = isAudioHelperPublished ?? false;
    } else {
      const teacher: TeacherState | undefined = store.getters["teacherRoom/teacher"];
      const isVideoTeacherPublished = isUserPublishedVideo({ userId: teacher?.id ?? "", gettersOrRootGetters: store.getters });
      const isAudioTeacherPublished = isUserPublishedAudio({ userId: teacher?.id ?? "", gettersOrRootGetters: store.getters });
      deviceStatus.camera = isVideoTeacherPublished ?? false;
      deviceStatus.microphone = isAudioTeacherPublished ?? false;
    }
    const options = { ...this.agoraClient.joinRoomOptions, camera: deviceStatus.camera, microphone: deviceStatus.microphone };
    await this.agoraClient.joinRTCRoom(options, true);
  }

  async manualReconnectAgoraRoom() {
    await this.reconnectAgoraRoom();
  }

  async close(end?: boolean) {
    await this.WSClient.disconnect();
    if (end) {
      await this.agoraClient.reset();
    } else {
      await this.agoraClient.leaveChannel();
    }
  }
}
