import { createStore } from "vuex";
import AnnotationModule from "./annotation";
import AppModule from "./app";
import AuthModule from "./auth";
import LessonModule from "./lesson";
import ModalModule from "./modal";
import NotificationModule from "./notification";
import ParentModule from "./parent";
import StudentRoomModule from "./room/student";
import TeacherRoomModule from "./room/teacher";
import SpinModule from "./spin";
import TeacherModule from "./teacher";
import ClassTeachingModule from "./teaching/class";
import StudentTeachingModule from "./teaching/student";
import TeacherTeachingModule from "./teaching/teacher";
import WhiteboardModule from "./whiteboard";
import CallingModule from "./calling";
import TeamsModule from "./teams";
import ObjectModule from "./object";
const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app: AppModule,
    auth: AuthModule,
    spin: SpinModule,
    parent: ParentModule,
    teacher: TeacherModule,
    teacherRoom: TeacherRoomModule,
    studentRoom: StudentRoomModule,
    lesson: LessonModule,
    notification: NotificationModule,
    annotation: AnnotationModule,
    classTeaching: ClassTeachingModule,
    studentTeaching: StudentTeachingModule,
    teacherTeaching: TeacherTeachingModule,
    modal: ModalModule,
    whiteboard: WhiteboardModule,
    calling: CallingModule,
    teams: TeamsModule,
    object: ObjectModule,
  },
  devtools: true,
});

export {
  store,
  AppModule,
  AuthModule,
  SpinModule,
  ParentModule,
  TeacherModule,
  TeacherRoomModule,
  StudentRoomModule,
  LessonModule,
  NotificationModule,
  AnnotationModule,
  ClassTeachingModule,
  StudentTeachingModule,
  TeacherTeachingModule,
  ModalModule,
  WhiteboardModule,
  CallingModule,
  TeamsModule,
  ObjectModule,
};
