import { CalendarSchedulesModel, ClassGroupModel, ClassModelSchedules, StudentGroupModel, TeacherClassCalendarModel } from "@/models";
import { GetterTree } from "vuex";
import { CalendarFilter, TeacherState } from "./state";
import { defineVuexName, VuexNames } from "@/store/utils";
import { FetchOngoingSessionModel } from "@/services";
import { ResourceModel } from "@/models/resource.model";

const getters: GetterTree<TeacherState, any> = {
  classes(state: TeacherState): Array<TeacherClassCalendarModel> {
    return state.classes;
  },
  classesSchedules(state: TeacherState): Array<ClassModelSchedules> {
    return state.classesSchedules;
  },
  classesSchedulesAllSchool(state: TeacherState): Array<ClassModelSchedules> {
    return state.classesSchedulesAllSchool;
  },
  schools(state: TeacherState): Array<ResourceModel> {
    return state.schools;
  },
  acceptPolicy(state: TeacherState): boolean {
    return state.acceptPolicy;
  },
  calendarSchedules(state: TeacherState): Array<CalendarSchedulesModel> {
    return state.calendarSchedules;
  },
  currentSchoolId(state: TeacherState): string {
    return state.currentSchoolId;
  },
  classesSchedulesBySchools(state: TeacherState): Array<ClassModelSchedules> {
    return state.classesSchedulesBySchools;
  },
  classSetUpStudents(state: TeacherState): Array<StudentGroupModel> {
    return state.classSetUpStudents;
  },
  classGroup(state: TeacherState): Array<ClassGroupModel> {
    return state.classGroup;
  },
  calendarFilter(state: TeacherState): CalendarFilter {
    return state.calendarFilter;
  },
  isHelper(state: TeacherState): boolean {
    return state.isHelper;
  },
  getIgnoreHelperRequestJoinClass(state: TeacherState): boolean {
    return state.ignoreHelperRequestJoinClass;
  },
  isHelperTakingOver(state: TeacherState): boolean {
    return state.isHelperTakingOver;
  },
  [defineVuexName(VuexNames.TEACHER.GETTERS.GET_ONGOING_SESSION)](state): FetchOngoingSessionModel | null {
    return state.ongoingSession;
  },
};

export default getters;
