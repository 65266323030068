import { FabricObject } from "@/ws";
import { fabric } from "fabric";
import FontFaceObserver from "fontfaceobserver";
import { debounce } from "lodash";
import { computed, ref, watch } from "vue";
import { DefaultCanvasDimension } from "vue-glcommonui";
import { useStore } from "vuex";
import { assignUniquePropertiesToNewlyCreatedCanvasObject, DEFAULT_TEXT_BOX_PROPS, FbEvents, FbObjects } from "@/utils/fabric-utils";
import { Logger } from "@/utils/logger";
import { ControlTextState } from "@/views/teacher-class/components/whiteboard-palette/whiteboard-palette";
import { useSendWebSocketMsg } from "@/hooks/use-send-websocket-msg";

const FontDidactGothic = "Didact Gothic";
let FontLoader = new FontFaceObserver(FontDidactGothic);

// eslint-disable-next-line
fabric.Textbox.prototype._wordJoiners = /[]/;

const deserializeFabricObject = (item: FabricObject) => {
  const { fabricData, fabricId } = item;
  const fabricObject = JSON.parse(fabricData);
  fabricObject.objectId = fabricId;
  return fabricObject;
};
export interface IOnObjectModifiedCallbacks {
  handleEditObjectFromCanvas: (event: any) => void;
}

export interface IOnSelectionChangedCallbacks {
  openTextControlPassively?: (event: any) => void;
}
export const useFabricObject = () => {
  // When teacher draw a line on the canvas, assign this variable as a unique ID to the line object
  const lineObjectId = ref("");
  const { dispatch, getters } = useStore();
  const { requestModifyTextBoxObject } = useSendWebSocketMsg();
  const isTeacher = computed(() => getters["auth/isTeacher"]);
  const currentExposureItemMedia = computed(() => getters["lesson/currentExposureItemMedia"]);
  const oneToOneId = computed(() => getters["teacherRoom/getStudentModeOneId"]);
  const nextColor = ref("");
  const nextFontFamily = ref("");
  const nextFontSize = ref(0);
  const currentSelectionEnd = ref(-1);
  const currentSelectionStart = ref(-1);
  const isChangeImage = ref(false);
  const updateNextStyles = (state: ControlTextState) => {
    if (state.fill) {
      nextColor.value = state.fill;
    }
    if (state.fontSize) {
      nextFontSize.value = state.fontSize;
    }
    if (state.fontFamily) {
      nextFontFamily.value = state.fontFamily;
    }
  };
  /**
   * Check if the canvas object is a text box
   */
  const isTextBox = (options: any) => options?.target?.type === FbObjects.Textbox;
  const isGroup = (target: any) => target?.type === FbObjects.Group;
  const isPath = (target: any) => target?.type === FbObjects.Path;
  const handlePreventSendMsg = (options: any, isPrevent: boolean) => {
    if (!options?.target) return;
    options.target.preventSendMsg = isPrevent;
  };
  const isPreventSendMsg = (options: any) => options?.target?.preventSendMsg;
  watch(currentExposureItemMedia, (currentItem, prevItem) => {
    if (currentItem && prevItem) {
      if (currentItem.id !== prevItem.id) {
        isChangeImage.value = true;
      }
    }
  });

  watch(oneToOneId, (currentOneToOneId, prevOneToOneId) => {
    if (currentOneToOneId !== prevOneToOneId && isChangeImage.value) {
      isChangeImage.value = false;
    }
  });

  const isEditing = ref(false);
  // Define a new function to handle object selection
  const handleObjectSelection = (options: any, isTeacher: boolean) => {
    if (options?.target.id !== "lesson-img" && !isTeacher) {
      options.target.selectable = false;
      options.target.evented = false;
    }
  };
  function lockAllObjectActions(target: any) {
    target.lockMovementX = true;
    target.lockMovementY = true;
    target.lockRotation = true;
    target.lockScalingX = true;
    target.lockScalingY = true;
    target.hoverCursor = "auto";
  }
  const onObjectCreated = (canvas: any) => {
    canvas.on(FbEvents.ObjectAdded, (options: any) => {
      handleObjectSelection(options, isTeacher.value);
      if (options.target.type === FbObjects.Path) {
        assignUniquePropertiesToNewlyCreatedCanvasObject(options.target, lineObjectId.value);
        lockAllObjectActions(options.target);
      }
    });
  };

  const onObjectModified = (canvas: any, callbacks?: IOnObjectModifiedCallbacks) => {
    canvas.on(FbEvents.ObjectModified, async (options: any) => {
      if (options?.target?.type === "group" && options.action === "drag") {
        const { target } = options.transform;
        const viewPortX = Math.abs(canvas.viewportTransform[4]);
        const viewPortY = Math.abs(canvas.viewportTransform[5]);
        const zoom = canvas.getZoom();
        const clipPathLeft = DefaultCanvasDimension.width - target.width;
        const clipPathTop = DefaultCanvasDimension.height - target.height;
        const originX = target.width / 2;
        const originY = target.height / 2;

        if (target.width * zoom < DefaultCanvasDimension.width) {
          if (target.left !== DefaultCanvasDimension.width / 2) {
            target.left = DefaultCanvasDimension.width / 2;
          }
        } else {
          if (target.left - viewPortX / zoom > originX) {
            target.left = originX + viewPortX / zoom;
          } else {
            if (target.left + viewPortX / zoom < originX + clipPathLeft) {
              target.left = originX + clipPathLeft - viewPortX / zoom;
            }
          }
        }
        if (target.height * zoom < DefaultCanvasDimension.height) {
          if (target.top !== target.height / 2) {
            target.top = target.height / 2 + viewPortY / zoom;
          }
        } else {
          if (target.top - viewPortY / zoom > originY) {
            target.top = originY + viewPortY / zoom;
          } else {
            if (target.top + viewPortY / zoom < originY) {
              target.top = originY + clipPathTop - viewPortY / zoom;
            }
          }
        }
        target.setCoords();
        const coords = {
          x: Math.floor(target.left) ?? 0,
          y: Math.floor(target.top) ?? 0,
          viewPortX: Math.floor(viewPortX),
          viewPortY: Math.floor(viewPortY),
        };
        if (canvas.getZoom() !== 1) {
          await dispatch("teacherRoom/setMoveZoomedSlide", coords);
          await dispatch("lesson/setImgCoords", coords, {
            root: true,
          });
        }
      }
      if (options?.target?.type === FbObjects.Textbox) {
        if (!isChangeImage.value) {
          if (isPreventSendMsg(options)) {
            handlePreventSendMsg(options, false);
          } else {
            await updateTextToRemoteUsers(options?.target);
          }
        }
        if (options?.target.text === "") {
          canvas.remove(options.target);
        }
      }
      if (isChangeImage.value) {
        isChangeImage.value = false;
      }
      if (options?.target?.type === FbObjects.Path) {
        callbacks?.handleEditObjectFromCanvas(options);
      }
    });
  };
  const updateTextToRemoteUsers = debounce(async (target: any) => {
    if (!target) return;
    target.customAttributes = target.customAttributes || {};
    target.customAttributes.svgStr = target.toSVG();
    await requestModifyTextBoxObject(target);
  }, 100);
  const onTextBoxEdited = (canvas: any) => {
    canvas.on(FbEvents.TextEditingExited, (options: any) => {
      if (options?.target.type === FbObjects.Textbox) {
        if (options?.target.text === "") {
          setTimeout(() => {
            canvas.remove(options.target);
          }, 0);
        }
      }
    });

    canvas.on(FbEvents.TextSelectionChanged, (options: any) => {
      currentSelectionEnd.value = options.target.selectionEnd;
      currentSelectionStart.value = options.target.selectionStart;
    });
    canvas.on(FbEvents.TextEditingEntered, (options: any) => {
      if (options?.target.type === FbObjects.Textbox) {
        options.target.set("cursorColor", nextColor.value);
        isEditing.value = true;
        options.target.setSelectionStart(0);
        options.target.setSelectionEnd(options.target.text.length);
      }
    });

    canvas.on(FbEvents.TextChanged, async (options: any) => {
      // adjust textbox size when remove characters
      if (options.target instanceof fabric.IText) {
        const maxLength = Math.max(...options.target.textLines.map((line: string) => line.length));
        options.target.set({ width: maxLength });
      }
      let startIndex = -1;
      let endIndex = -1;
      if (nextColor.value) {
        if (currentSelectionEnd.value === currentSelectionStart.value) {
          if (options.target?.prevTextValue?.length < options.target?.text?.length) {
            startIndex = currentSelectionEnd.value;
            endIndex = options.target.selectionEnd;
          }
        } else {
          startIndex = currentSelectionStart.value;
          endIndex = options.target.selectionEnd;
        }
      }
      if (startIndex > -1 && endIndex > -1) {
        const selectedTextStyles = options.target.getSelectionStyles(startIndex, endIndex, true);
        if (
          selectedTextStyles?.some(
            (style: any) =>
              style && (style.fill !== nextColor.value || style.fontFamily !== nextFontFamily.value || style.fontSize !== nextFontSize.value),
          )
        ) {
          options.target.setSelectionStart(startIndex);
          options.target.setSelectionEnd(endIndex);
          options.target.setSelectionStyles({
            fill: nextColor.value,
            fontFamily: nextFontFamily.value,
            fontSize: nextFontSize.value,
          });
          options.target.setSelectionStart(options.target.selectionEnd);
          options.target.setSelectionEnd(options.target.selectionEnd);
        }
      }
      options.target.prevTextValue = options.target.text;
      currentSelectionEnd.value = options.target.selectionEnd;
      currentSelectionStart.value = options.target.selectionEnd;
      await updateTextToRemoteUsers(options?.target);
    });
  };

  const createTextBox = async (canvas: any, coords: { top: number; left: number }, customTextBoxProps: ControlTextState) => {
    updateNextStyles(customTextBoxProps);
    const textBox = new fabric.Textbox("", {
      ...DEFAULT_TEXT_BOX_PROPS,
      ...customTextBoxProps,
      ...coords,
    });
    assignUniquePropertiesToNewlyCreatedCanvasObject(textBox);
    textBox.prevTextValue = "";
    canvas.add(textBox).setActiveObject(textBox);
    textBox.enterEditing();
    textBox.setSelectionStart(0);
    textBox.setSelectionEnd(textBox.text.length);
    return textBox;
  };

  //display the fabric items get from getRoomInfo API which save in vuex store
  const displayFabricItems = (canvas: any, items: FabricObject[]) => {
    for (const item of items) {
      const currentObjects = canvas.getObjects();
      const existing = currentObjects.find((obj: any) => obj.objectId === item.fabricId);
      if (existing) {
        const fabricObject = deserializeFabricObject(item);
        FontLoader = new FontFaceObserver(fabricObject.fontFamily);
        const { type } = fabricObject;
        switch (type) {
          case FbObjects.Textbox:
            FontLoader.load().then(() => {
              canvas.add(new fabric.Textbox("", fabricObject));
            });
            break;
          default:
            break;
        }
        return;
      }
      displayCreatedItem(canvas, item);
    }
  };

  //display the fabric item get from signalR TeacherCreateFabricObject/TeacherModifyFabricObject
  //which save in vuex store as lastFabricUpdated
  const displayCreatedItem = (canvas: any, item: FabricObject) => {
    const fabricObject = deserializeFabricObject(item);
    const { type } = fabricObject;
    FontLoader = new FontFaceObserver(fabricObject.fontFamily);
    switch (type) {
      case FbObjects.Textbox: {
        const emptyTextBox = canvas.getObjects().find((obj: any) => obj.type === FbObjects.Textbox && !obj.text);
        if (emptyTextBox) {
          canvas.remove(emptyTextBox);
        }
        FontLoader.load().then(() => {
          canvas.add(new fabric.Textbox("", fabricObject));
        });
        break;
      }
      default:
        break;
    }
  };

  const displayModifiedItem = (canvas: any, item: FabricObject, existingItem: any) => {
    const fabricObject = deserializeFabricObject(item);
    const { type } = fabricObject;
    switch (type) {
      case FbObjects.Textbox:
        //two lines below fix the bug the text not display when texts's width not equal the Box's width (it can be fabric issue)
        if (fabricObject.text.length === 1) fabricObject.width = 0;
        existingItem.set(fabricObject);
        canvas.renderAll();
        break;
      default:
        break;
    }
  };
  const updateSelectedObjectStyles = async (canvas: any, state: ControlTextState) => {
    updateNextStyles(state);
    const selectedObj = canvas.getActiveObject();
    if (selectedObj?.type === FbObjects.Textbox) {
      const styles = { fill: state.fill, fontFamily: state.fontFamily, fontSize: state.fontSize };
      const start = selectedObj.isEditing ? selectedObj.selectionStart : 0;
      const end = selectedObj.isEditing ? selectedObj.selectionEnd : selectedObj.text.length;
      selectedObj.setSelectionStyles(styles, start, end);
      selectedObj.set("cursorColor", state.fill);
      canvas.renderAll();
      await updateTextToRemoteUsers(selectedObj);
    }
  };

  const onObjectRemoved = (canvas: any) => {
    canvas.on(FbEvents.ObjectRemoved, () => {
      // Logger.log(FbEvents.ObjectRemoved, options?.target);
    });
  };

  const onObjectSelected = (canvas: any) => {
    canvas.on(FbEvents.ObjectSelected, (options: any) => {
      Logger.log(FbEvents.ObjectSelected, options?.target);
    });
  };

  const onSelectionChanged = (canvas: any, callbacks?: IOnSelectionChangedCallbacks) => {
    canvas.on(FbEvents.SelectionCreated, async (options: any) => {
      isTextBox(options) && handlePreventSendMsg(options, false);
      callbacks?.openTextControlPassively?.(options.target);
    });
    canvas.on(FbEvents.BeforeSelectionCleared, async (options: any) => {
      isTextBox(options) && handlePreventSendMsg(options, true);
    });
    canvas.on(FbEvents.SelectionUpdated, async (options: any) => {
      isTextBox(options) && handlePreventSendMsg(options, false);
      callbacks?.openTextControlPassively?.(options.target);
    });
  };

  return {
    onObjectCreated,
    createTextBox,
    onTextBoxEdited,
    onObjectModified,
    displayFabricItems,
    displayCreatedItem,
    displayModifiedItem,
    isEditing,
    nextColor,
    FontLoader,
    onObjectRemoved,
    onObjectSelected,
    updateSelectedObjectStyles,
    onSelectionChanged,
    updateNextStyles,
    isGroup,
    isTextBox,
    isPath,
    lineObjectId,
  };
};
