import { GLServiceBase } from "vue-glcommonui";
import { IndependentServiceInterface } from "./interface";
import { GetIndependentStudentResponse, GetIndependentStudentsResponse, ToggleIndependentModeResponse } from "./model";
import { UserRequestModel } from "@/ws";
import { store } from "@/store";
class IndependentServiceClass extends GLServiceBase<any, any> implements IndependentServiceInterface {
  serviceRoute = { prefix: "remote/v1" };

  get userRequestModel(): UserRequestModel {
    return {
      sessionId: store.getters["classTeaching/getSessionId"],
    };
  }
  toggleIndependentMode(unit: number, lesson: number, itemId?: string): Promise<ToggleIndependentModeResponse> {
    const { sessionId } = this.userRequestModel;
    const isStart = !!itemId;
    const constantParams = `isStart=${isStart}&unit=${unit}&lesson=${lesson}&sessionId=${sessionId}`;
    const dynamicParams = isStart ? `&itemId=${itemId}` : "";
    return this.create(`teacher/independent?${constantParams + dynamicParams}`);
  }
  getIndependentStudentsItem(): Promise<GetIndependentStudentsResponse> {
    return this.get(`teacher/independent`, { sessionId: this.userRequestModel.sessionId });
  }
  helperGetIndependentStudentsItem(): Promise<GetIndependentStudentsResponse> {
    return this.get(`helper/independent`, { sessionId: this.userRequestModel.sessionId });
  }
  getIndependentStudentItem(studentId: string): Promise<GetIndependentStudentResponse> {
    return this.get(`student/independent/current`, { studentId, sessionId: this.userRequestModel.sessionId });
  }
}

export const IndependentService = new IndependentServiceClass();
