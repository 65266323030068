// these are from RemoteTeachingHub.cs at server side
export enum TeacherWSCommand {
  JOIN_CLASS = "TeacherJoinClass",
  SET_TEACHING_MODE = "TeacherSetTeachingMode",
  START_LESSON_CONTENT = "TeacherSetContent",
  END_LESSON_CONTENT = "TeacherEndContent",
  SET_LESSON_ITEM_CONTENT = "TeacherSetItemContent",
  SET_CLASS_ACTION = "TeacherSetClassAction",
  TEACHER_SET_POINTER = "TeacherSetPointer",
  TEACHER_USE_ONLY_SET_POINTER = "TeacherUseOnlySetPointer",
  TEACHER_UPDATE_ANNOTATION_MODE = "TeacherUpdateAnnotationMode",
  TEACHER_USE_ONLY_UPDATE_ANNOTATION_MODE = "TeacherUseOnlyUpdateAnnotationMode",
  TEACHER_CLEAR_ALL_BRUSH_STROKES = "TeacherClearAllBrushstrokes",
  TEACHER_USE_ONLY_CLEAR_ALL_BRUSH_STROKES = "TeacherUseOnlyClearAllBrushstrokes",
  TEACHER_SET_ONE_TO_ONE = "TeacherSetOneToOne",
  TEACHER_SET_WHITEBOARD = "TeacherSetWhiteBoard",
  TEACHER_DRAW_LASER_PEN = "TeacherDrawLaserPen",
  TEACHER_USE_ONLY_DRAW_LASER_PEN = "TeacherUseOnlyDrawLaserPen",
  TEACHER_DRAW_PENCIL_PEN = "TeacherDrawPencilPen",
  TEACHER_USE_ONLY_DRAW_PENCIL_PEN = "TeacherUseOnlyDrawPencilPen",
  TEACHER_CREATE_FABRIC_OBJECT = "TeacherCreateFabricObject",
  TEACHER_USE_ONLY_CREATE_FABRIC_OBJECT = "TeacherUseOnlyCreateFabricObject",
  TEACHER_MODIFY_FABRIC_OBJECT = "TeacherModifyFabricObject",
  TEACHER_USE_ONLY_MODIFY_FABRIC_OBJECT = "TeacherUseOnlyModifyFabricObject",
  TEACHER_TOGGLE_SHAPE = "TeacherToggleShape",
  UPDATE_SESSION_LESSON_AND_UNIT = "TeacherUpdateSessionLessonAndUnit",
  TEACHER_ZOOM_SLIDE = "TeacherZoomSlide",
  TEACHER_USE_ONLY_ZOOM_SLIDE = "TeacherUseOnlyZoomSlide",
  TEACHER_MOVE_ZOOMED_SLIDE = "TeacherMoveZoomedSlide",
  TEACHER_USE_ONLY_MOVE_ZOOMED_SLIDE = "TeacherUseOnlyMoveZoomedSlide",
  HELPER_UPDATE_IGNORE_TEACHER_AUDIO = "HelperToggleTeacherAudio",
  TEACHER_SCROLL_PDF_VIEW = "TeacherScrollPdfView",
  TEACHER_USE_ONLY_SCROLL_PDF_VIEW = "TeacherUseOnlyScrollPdfView",
}
