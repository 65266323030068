// Three kinds of users model
export interface MemberState {
  id: string;
  videoCallStatus: VideoCallStatus;
}
export interface StudentState extends MemberState {
  index: number;
  name: string;
  englishName: string;
  avatar?: string;
  badge: number;
  status: InClassStatus;
  raisingHand: boolean;
  isPalette: boolean;
}

export interface TeacherState extends MemberState {
  name: string;
  avatar: string;
  status: InClassStatus;
  disconnectTime?: number | null;
  signalRConnectionId: string | null;
}

export interface HelperState extends MemberState {
  name: string;
  avatar: string;
  signalRStatus: ConnectionStatus;
  isVideoShownByTeacher: boolean;
  signalRConnectionId: string | null;
}

export interface LessonInfo {
  unit: string;
  lesson: string;
}

export enum InClassStatus {
  /**Student is not join the class yet */
  DEFAULT = 0,
  /**
   * Student send a request to Join the class, it depends on the setting of the class. If student can join class at any time without any restrictions then this value is not in use.
   */
  // REQUESTING = 1,
  /**
   * Student is now in the class
   */
  JOINED = 2,
  /**
   * Student is about to leave the class
   */
  LEAVING = 3,
  /**
   * Student has left the class
   */
  LEFT = 4,
  /**
   * Student has left the class
   */
  // DISCONNECTED = 5,
}

export enum ConnectionStatus {
  Disconnected = 0, // disconnected
  Joined = 2,
}

export enum VideoCallStatus {
  Disconnected = 0, // disconnected
  Joined = 2,
}

export enum StreamingStatus {
  WAITING = 0,
  CONNECTING = 1,
  CONNECTED = 2,
  DISCONNECTED = 3,
}

export interface UserIdPayload {
  id: string;
}
export interface UserMediaPayload {
  id: string;
  enable: boolean;
}
export interface IndependentStudentStatus {
  studentId: string;
  currentItemId: string;
}
export interface DeviceMediaPayload {
  enable: boolean;
}

export interface StudentBadgePayload {
  id: string;
  badge: number;
}

export type DefaultPayload = any;

export interface InitClassRoomPayload {
  classId: string;
  userId: string;
  userName: string;
  role: string;
  deviceId: string;
  isHelper?: boolean;
  groupId?: string;
  callFirstTime: boolean;
}

export interface NetworkQualityPayload {
  uplinkNetworkQuality: number;
  downlinkNetworkQuality: number;
}

export interface HelperRequestJoinClassPayload {
  name: string;
  id: string;
  deviceId: string;
}

export interface ParamsToJoinCurSessionInterface {
  deviceId: string;
  groupId: string;
}

export const networkQualityStats = {
  "0": 0, //The network quality is unknown.
  "1": 1, //The network quality is excellent.
  "2": 2, //The network quality is quite good, but the bitrate may be slightly lower than excellent.
  "3": 3, //Users can feel the communication slightly impaired.
  "4": 4, //Users can communicate only not very smoothly.
  "5": 5, //The network is so bad that users can hardly communicate.
  "6": 6, //The network is down and users cannot communicate at all.
};

export const lowBandWidthPoint = networkQualityStats["4"];
export const SHOW_LOW_BANDWIDTH_NOTIFICATION_DURATION = 10; //10s

export interface PencilPayloadInterface {
  size: number;
  color: string;
}

export interface ShapePayloadInterface {
  userId: string;
  brushstrokes: string[];
}

export interface FabricPayloadInterface {
  fabricId: string;
  fabricData: string;
}

export interface VisibleShapePayloadInterface {
  userId: string;
  tag: string;
  visible: boolean;
}
export interface DrawingPayloadInterface {
  pencil: PencilPayloadInterface;
  brushstrokes: string[];
  studentBrushstrokes: string[];
  shapes: ShapePayloadInterface[];
  fabrics: FabricPayloadInterface[];
  isShowingAllShapes: boolean;
  visibleShapes: VisibleShapePayloadInterface[];
}

export interface PositionPayloadInterface {
  x: number;
  viewportX?: number;
  y: number;
  viewportY?: number;
}
export interface OneToOneModeResponse {
  studentOneToOneId: string;
  enable: boolean;
}

export interface TeacherSetMediaStateReceiveResponse {
  startMediaAt: null | string;
  duration: number;
  pauseMediaAt: null | string;
}

export interface OnTeacherToggleStudentPalette {
  id: string;
  isPalette: boolean;
}

export interface OnTeacherClearRaisingHand {
  id: string;
}

export interface OnTeacherUpdateStudentBadgeItem {
  id: string;
  badge: number;
}

export interface RequestSetExposureItem {
  exposureItemId: string;
  exposureId: string;
}

export interface OnTeacherSetLessonPlanItemContent extends RequestSetExposureItem {
  timestamp: number;
}
