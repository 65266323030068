import { TeachingActivityTarget } from "@/store/lesson/state";
import { FabricObject } from "@/ws";
import { HelperModel } from "./helper.model";
import { StudentModel } from "./student.model";
import { TeacherModel } from "./teacher.model";
import { OneToOneDto } from "@/services";

// defined from BE
export enum MediaType {
  none = -1,
  pdf = 1,
  mp3,
  mp4,
  png,
}
export enum MediaSlot {
  ViewingContent = 1,
  AlternateContent = 2,
  Audio = 3,
  AudioBackground = 4,
  StreamingVideo = 5,
  OfflineVideo = 6,
  TeacherManual = 7,
  Thumbnail = 8,
  ExposureAudio = 9,
  ExposureVideo = 10,
  Image = 11,
  IndividualAudio = 12,
}
export enum AnnotationMode {
  Default = 0,
  PointerMode = 1,
  DrawMode = 2,
  StickerMode = 3,
}
export interface ExposureItemMediaModel {
  id: string;
  url: string;
  resolution: string;
  teacherUseOnly: boolean;
  pageTiming?: string;
  pageTimingToMillisecond?: number;
  image?: {
    url: string;
    width: number;
    height: number;
  };
}
export interface AlternateItemMediaModel {
  contentId: string;
  id: string;
  mediaSlotId: MediaSlot;
  mediaStatusId: number;
  mediaTypeId: number;
  page: Array<ExposureItemMediaModel>;
  revision: number;
  url: string;
}
export interface ExposureItemModel {
  id: string;
  unit: number;
  title: string;
  page: Array<ExposureItemMediaModel>;
}

export interface TeachingActivityModel {
  contentId: any;
  id: string;
  isCommon: boolean;
  text: string;
  versionGroupId: string;
}
export interface TeachingActivityItemModel {
  contentExposureId: string;
  imageName: any;
  imageUrl: any;
  metaData: any;
  pageId: any;
  sequence: number;
  teachingActivity: TeachingActivityModel;
  teachingActivityId: string;
}

export interface ReaderContentModel {
  contentId: string;
  contentTitle: string;
  skillTypeId: number;
  medias: ReaderContentMediaModel[];
  pages: ReaderContentPageModel[];
  focusWords: ReaderContentFocusWordModel[];
}

export interface ReaderContentMediaModel {
  id: string;
  mediaTypeId: number;
  revision: number;
  contentId: string;
  url: string;
  languageCode: string;
  mediaStatusId: number;
  mediaSlotId: number;
  page: ReaderContentMediaPageModel[];
}

export interface ReaderContentMediaPageModel {
  id: string;
  url: string;
  sequence: number;
  resolution: any;
  teacherUseOnly: boolean;
}

export interface ReaderContentPageModel {
  id: string;
  url: string;
  audioMediaId?: string;
}

export interface ReaderContentFocusWordModel {
  focusWordText: string;
  focusWordDefinition: string;
  medias: ReaderContentFocusWordMediaModel[];
  pages: any[];
}

export interface ReaderContentFocusWordMediaModel {
  id: string;
  mediaTypeId: number;
  revision: number;
  contentId: string;
  url: string;
  languageCode: string;
  mediaStatusId: number;
  mediaSlotId: number;
  page: any;
}

export interface ExposureContentModel {
  id: string;
  title: string;
  pageSelected: string;
  contentType: {
    id: number;
    name: string;
  };
  unit: number;
  played: boolean;
  maxDuration: string;
  contents: Array<ExposureItemModel>;
  contentExposureTeachingActivity: Array<TeachingActivityItemModel>;
  page: Array<ExposureItemMediaModel>;
  thumbnailUrl: any;
  contentRootType: number;
  media: Array<AlternateItemMediaModel>;
  readerContents: ReaderContentModel[];
}

export interface PencilModel {
  size: number;
  color: string;
}
export interface UserShapeModel {
  userId: string;
  brushstrokes: Array<string>;
}
export interface DrawingModel {
  pencil: PencilModel | null;
  brushstrokes: Array<string>;
  studentShapes: UserShapeModel[];
  teacherShapes: UserShapeModel[];
  studentBrushstrokes: Array<string>;
  shapes: UserShapeModel[];
  fabrics: FabricObject[];
  isShowingAllShapes: boolean; // có thể bỏ, trong app cũng không dùng
  visibleShapes: TeachingActivityTarget[];
}

export interface AnnotationModel {
  mode: number;
  pointer: { x: number; y: number };
  drawing: DrawingModel;
  oneToOne: DrawingModel;
  oneOneDrawing: DrawingModel;
}

export interface ClassRoomModel {
  classId: string;
  className: string;
  groupId: string;
  groupName: string;
  lesson: number;
  unit: number;
  deviceId?: string;
}

export interface LessonPlanModel {
  contentSelected: string;
  contents: Array<ExposureContentModel>;
  contentStorageUrl: string;
  totalTime: string;
  playedTime: string;
  lessonAction: number;
  ratio: number;
  position:
    | {
        x: number;
        y: number;
      }
    | null
    | undefined;
}

export interface RoomSessionModel extends RoomModel {
  options?: {
    isActionUpToDate: boolean;
    // Check current user is HELPER or not
    isHelper?: boolean;
  };
}

export interface RoomModel {
  id: string;
  status: number;
  teacher: TeacherModel;
  helper: HelperModel;
  streamInfo: {
    token: string;
    chanelId: string;
    userId: string;
    appId: string;
  };
  studentOneToOne: string;
  studentHelperOneToOne: string;
  classInfo: ClassRoomModel;
  lessonPlan: LessonPlanModel;
  students: Array<StudentModel>;
  contents: Array<any>;
  studentsAudio: Array<string>;
  annotation: null | AnnotationModel;
  oneAndOneDto: OneToOneDto;
  helperOneAndOneDto: OneToOneDto;
  independentMode: boolean;
  oneToOneWithAnotherTeacherStudentId?: string;
  ratioScrollPdf: number;
  isTeamMode: boolean;
  state: NormalSessionState;
}

export interface NormalSessionState {
  teachingMode: TeachingMode;
  isShowWhiteBoard: boolean;
}

export enum TeachingMode {
  CloseLessonPlan = 1,
  OpenLessonPlan = 2,
}

export interface UnitAndLessonModel {
  lessonPlanVersionId: string;
  sequence: number;
  unit: number;
  unitId: number;
}
