import { GetterTree } from "vuex";
import { StudyMode } from "../interfaces";
import { ClassTeachingState } from "./state";
import { OneToOneDto } from "@/services";
import { defineVuexName, VuexNames } from "@/store/utils";

const getters: GetterTree<ClassTeachingState, any> = {
  getSessionId(state: ClassTeachingState): string {
    return state.sessionId;
  },
  isIndependentMode(state: ClassTeachingState): boolean {
    return state.mode === StudyMode.Independent;
  },
  getIndependentModeCurrentUnit(state: ClassTeachingState): number | null {
    return state.independentModeCurrentUnit;
  },
  getIndependentModeCurrentLesson(state: ClassTeachingState): number | null {
    return state.independentModeCurrentLesson;
  },
  getOneToOneWithHelperIgnoreTeacherVoice(state: ClassTeachingState): boolean {
    return state.oneToOneWithHelperIgnoreTeacherVoice;
  },
  getMyOneAndOneData(state: ClassTeachingState): null | OneToOneDto {
    return state.myOneAndOneData;
  },
  [defineVuexName(VuexNames.CLASS_TEACHING.GETTERS.GET_INITIAL_CLASS_DATA_SNAPSHOT_EXPOSURE_ID)](state: ClassTeachingState): string | undefined {
    return state.initialClassDataSnapshot.exposureId;
  },
  [defineVuexName(VuexNames.CLASS_TEACHING.GETTERS.GET_IS_SOCKET_ATTEMPTED_RECONNECTING)](state: ClassTeachingState): boolean {
    return state.isSocketAttemptedReconnecting;
  },
  [defineVuexName(VuexNames.CLASS_TEACHING.GETTERS.GET_IS_INIT_CLASS_FAILED)](state: ClassTeachingState): boolean {
    return state.isInitClassFailed;
  },
  [defineVuexName(VuexNames.CLASS_TEACHING.GETTERS.GET_IS_INDEPENDENT_MODE_ENABLED)](state: ClassTeachingState): boolean {
    return state.mode === StudyMode.Independent;
  },
  [defineVuexName(VuexNames.CLASS_TEACHING.GETTERS.GET_IS_MUTE_TEACHER_AUDIO_WHEN_ONE_TO_ONE_WITH_HELPER)](state: ClassTeachingState): boolean {
    return state.oneToOneWithHelperIgnoreTeacherVoice;
  },
};

export default getters;
