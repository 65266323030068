import { fabric } from "fabric";
import { getFixedStrokeSizeProperties } from "@/utils/fabric-utils";
import { UserShape } from "@/store/annotation/state";

export const brushstrokesRender = (canvas: any, data: UserShape, oneId: string | null, tag: string) => {
  data.brushstrokes.forEach((s: any) => {
    const shape = JSON.parse(s);
    if (shape.type === "rect") {
      new fabric.Rect.fromObject(shape, (item: any) => {
        item.isOneToOne = oneId;
        item.tag = tag;
        canvas.add(item);
        item.selectable = false;
        item.setOptions(getFixedStrokeSizeProperties());
      });
    }
    if (shape.type === "circle") {
      new fabric.Circle.fromObject(shape, (item: any) => {
        item.isOneToOne = oneId;
        item.tag = tag;
        canvas.add(item);
        item.selectable = false;
        item.setOptions(getFixedStrokeSizeProperties());
      });
    }
  });
};
