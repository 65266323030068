import { StudyMode } from "../interfaces";
import { OneToOneDto } from "@/services";
export interface InitialClassDataSnapshot {
  exposureId: string | undefined;
}
export interface ClassTeachingState {
  sessionId: string;
  groupId: string;
  classId: string;
  mode: StudyMode;
  independentModeCurrentUnit: number | null;
  independentModeCurrentLesson: number | null;
  oneToOneWithHelperIgnoreTeacherVoice: boolean;
  myOneAndOneData: null | OneToOneDto;
  initialClassDataSnapshot: InitialClassDataSnapshot;
  isSocketAttemptedReconnecting: boolean;
  isInitClassFailed: boolean;
}
export const initState = () => ({
  sessionId: "",
  groupId: "",
  classId: "",
  mode: StudyMode.Normal,
  independentModeCurrentUnit: null,
  independentModeCurrentLesson: null,
  oneToOneWithHelperIgnoreTeacherVoice: false,
  //* Save the local user's 1:1 data */
  myOneAndOneData: null,
  // initialClassDataSnapshot is used to store the initial class data snapshot when the user enters the class
  initialClassDataSnapshot: {
    // exposureId of the class (not 1:1)
    exposureId: undefined,
  },
  isSocketAttemptedReconnecting: false,
  isInitClassFailed: false,
});

const state: ClassTeachingState = initState();

export default state;
